import * as React from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  Input,
  addCompleteStep,
  setInput,
} from "../../../redux/reducers/Enrollment";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import TextInput from "../inputs/TextInput";
import RadioInput from "../inputs/RadioInput";
import DateInput from "../inputs/DateInput";
import SelectInput from "../inputs/SelectInput";

import {
  ETHNICITY_OPTIONS,
  FormRootProps,
  GENDER_IDENTITY_OPTIONS,
  RACE_OPTIONS,
  SELF_IDENTITY_OPTIONS,
} from "../../../types/Enrollment.types";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

const SEX_OPTIONS = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
];

const TITLE_OPTIONS = [
  {
    value: "Mr",
    label: "Mr.",
  },
  {
    value: "Mrs",
    label: "Mrs.",
  },
  {
    value: "Ms",
    label: "Ms.",
  },
];

interface FormInput {
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Sex: string;
  Title?: string;
  Birthday: string;
  Race: string;
  OtherRace: string;
  Ethnicity: string;
  OtherEthnicity: string;
  GenderIdentity: string;
  OtherGenderIdentity: string;
  SelfIdentity: string;
  OtherSelfIdentity: string;
}

export default function ApplicantInformation({
  handleNavigate,
}: FormRootProps) {
  const dispatch = useAppDispatch();
  const analytics = useRudderStackAnalytics();

  const {
    ApplicationID,
    EnrollPlan,
    Carrier,
    Input: InputtedValues,
  } = useAppSelector((state) => state.Enrollment);
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const methods = useForm<FormInput>({
    defaultValues: {
      FirstName:
        (InputtedValues["applicant-information"]?.FirstName as string) ?? "",
      LastName:
        (InputtedValues["applicant-information"]?.LastName as string) ?? "",
      MiddleName:
        (InputtedValues["applicant-information"]?.MiddleName as string) ?? "",
      Sex: (InputtedValues["applicant-information"]?.Sex as string) ?? "",
      Title: (InputtedValues["applicant-information"]?.Title as string) ?? "",
      Birthday:
        (InputtedValues["applicant-information"]?.Birthday as string) ?? "",
      Race: (InputtedValues["applicant-information"]?.Race as string) ?? [],
      OtherRace:
        (InputtedValues["applicant-information"]?.OtherRace as string) ?? "",
      Ethnicity:
        (InputtedValues["applicant-information"]?.Ethnicity as string) ?? [],
      OtherEthnicity:
        (InputtedValues["applicant-information"]?.OtherEthnicity as string) ??
        "",
      GenderIdentity:
        (InputtedValues["applicant-information"]?.GenderIdentity as string) ??
        "",
      OtherGenderIdentity:
        (InputtedValues["applicant-information"]
          ?.OtherGenderIdentity as string) ?? "",
      SelfIdentity:
        (InputtedValues["applicant-information"]?.SelfIdentity as string) ?? "",
      OtherSelfIdentity:
        (InputtedValues["applicant-information"]
          ?.OtherSelfIdentity as string) ?? "",
    },
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, formState, watch } = methods;
  const { errors } = formState;

  const raceValue = watch("Race");
  const ethnicityValue = watch("Ethnicity");
  const genderIdentityValue = watch("GenderIdentity");
  const selfIdentityValue = watch("SelfIdentity");

  React.useEffect(() => {
    if (!analytics || !EnrollPlan || !ApplicationID || !QuoteID) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_STARTED, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      section_id: "applicant-information",
    });
  }, [ApplicationID, EnrollPlan, QuoteID, analytics]);

  React.useEffect(() => {
    if (!analytics || !EnrollPlan || !ApplicationID || !QuoteID) {
      return;
    }

    const { errors } = formState;
    if (errors) {
      Object.keys(errors).forEach((i) => {
        const errorMessage = errors[i as keyof FormInput]?.message;

        if (errorMessage) {
          analytics?.track(
            ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_ERROR,
            {
              quote_id: QuoteID,
              application_id: ApplicationID,
              enrollment_type: "INTERNAL",
              contract_id: EnrollPlan?.ContractID,
              plan_id: EnrollPlan?.PlanID,
              segment_id: EnrollPlan?.SegmentID,
              plan_year: EnrollPlan?.Year,
              snp_type: EnrollPlan?.SnpType,
              plan_type: EnrollPlan?.PlanType,
              section_id: "applicant-information",
              error_field: i,
              error_detail: errorMessage,
            }
          );
        }
      });
    }
  }, [formState, analytics, EnrollPlan, ApplicationID, QuoteID]);

  const onSubmit: SubmitHandler<FormInput> = (data) => {
    const inputData: Input = {
      "applicant-information": {
        ...data,
        OtherRace: raceValue.includes("000") ? data.OtherRace : "",
        OtherEthnicity: ethnicityValue.includes("OTHER")
          ? data.OtherEthnicity
          : "",
        OtherGenderIdentity:
          genderIdentityValue === "4" ? data.OtherGenderIdentity : "",
        OtherSelfIdentity:
          selfIdentityValue === "4" ? data.OtherSelfIdentity : "",
      },
    };

    dispatch(setInput(inputData));
    dispatch(addCompleteStep("applicant-information"));

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_SUBMITTED, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      section_id: "applicant-information",
    });

    handleNavigate("contact-information");
  };

  const IsHumanaPlan = React.useMemo(() => {
    return /humana/i.test(Carrier ?? "");
  }, [Carrier]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioInput
              name="Title"
              control={control}
              label="Title"
              options={TITLE_OPTIONS}
              errorMessage={errors.Title?.message}
              validation={{
                pattern: {
                  value: /^[A-Za-z.]*$/,
                  message: "Only letters (No symbols)",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <TextInput
              name="FirstName"
              control={control}
              errorMessage={errors.FirstName?.message}
              label="First Name"
              placeholder="Enter your First Name"
              required
              validation={{
                required: {
                  value: true,
                  message: "This field is required",
                },
                pattern: {
                  value: /^[A-Za-z. ]{1,50}$/,
                  message: "Only letters (No symbols)",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <TextInput
              name="MiddleName"
              control={control}
              errorMessage={errors.MiddleName?.message}
              label="Middle Name"
              placeholder="Enter your Middle Name"
              validation={{
                pattern: {
                  value: /^[A-Za-z. ]{0,50}$/,
                  message: "Only letters (No symbols)",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <TextInput
              name="LastName"
              control={control}
              errorMessage={errors.LastName?.message}
              label="Last Name"
              placeholder="Enter your Last Name"
              required
              validation={{
                required: {
                  value: true,
                  message: "This field is required",
                },
                pattern: {
                  value: /^[A-Za-z. ]{0,50}$/,
                  message: "Only letters (No symbols)",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <DateInput
              name="Birthday"
              control={control}
              label="Date of Birth"
              required
              errorMessage={errors.Birthday?.message}
              validation={{
                required: {
                  value: true,
                  message: "This field is required",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <RadioInput
              name="Sex"
              control={control}
              label="What was your sex assigned at birth?"
              required
              options={SEX_OPTIONS}
              errorMessage={errors.Sex?.message}
              validation={{
                required: {
                  value: true,
                  message: "This field is required",
                },
                pattern: {
                  value: /^[A-Za-z.]*$/,
                  message: "Only letters (No symbols)",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12} sm={6} md={5}>
            <SelectInput
              name="Race"
              control={control}
              label="Race"
              options={RACE_OPTIONS}
              errorMessage={errors.Race?.message}
              multiselect
            />
            {IsHumanaPlan && raceValue?.includes("000") && (
              <Box mt={1}>
                <TextInput
                  name="OtherRace"
                  control={control}
                  errorMessage={errors.OtherRace?.message}
                  label="Specify Other Race"
                  placeholder="Please Specify Other"
                  validation={{
                    pattern: {
                      value: /^[A-Za-z. ]{0,50}$/,
                      message: "Only letters (No symbols)",
                    },
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <SelectInput
              name="Ethnicity"
              control={control}
              label="Ethnicity"
              options={ETHNICITY_OPTIONS}
              errorMessage={errors.Ethnicity?.message}
              multiselect
            />
            {IsHumanaPlan && ethnicityValue?.includes("OTHER") && (
              <Box mt={1}>
                <TextInput
                  name="OtherEthnicity"
                  control={control}
                  errorMessage={errors.OtherEthnicity?.message}
                  label="Specify Other Ethnicity"
                  placeholder="Please Specify Other"
                  validation={{
                    pattern: {
                      value: /^[A-Za-z. ]{0,50}$/,
                      message: "Only letters (No symbols)",
                    },
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <SelectInput
              name="GenderIdentity"
              control={control}
              label="What's your gender identity?"
              options={GENDER_IDENTITY_OPTIONS}
              errorMessage={errors.GenderIdentity?.message}
            />
            {genderIdentityValue === "4" && (
              <Box mt={1}>
                <TextInput
                  name="OtherGenderIdentity"
                  control={control}
                  errorMessage={errors.OtherGenderIdentity?.message}
                  label="Specify Other Gender Identity"
                  placeholder="Please Specify Other"
                  validation={{
                    pattern: {
                      value: /^[A-Za-z. ]{0,25}$/,
                      message: "Only 25 letters (No symbols)",
                    },
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <SelectInput
              name="SelfIdentity"
              control={control}
              label="What's your sexual orientation?"
              options={SELF_IDENTITY_OPTIONS}
              errorMessage={errors.SelfIdentity?.message}
            />
            {selfIdentityValue === "4" && (
              <Box mt={1}>
                <TextInput
                  name="OtherSelfIdentity"
                  control={control}
                  errorMessage={errors.OtherSelfIdentity?.message}
                  label="Specify Other Self Identity"
                  placeholder="Please Specify Other"
                  validation={{
                    pattern: {
                      value: /^[A-Za-z. ]{0,25}$/,
                      message: "Only 25 letters (No symbols)",
                    },
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} container justifyContent="flex-end">
            <Button variant="contained" color="primary" type="submit">
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
