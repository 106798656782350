import * as React from "react";
import { useAppSelector } from "../../../redux/hooks";

import {
  Typography,
  useMediaQuery,
  Theme,
  Grid,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Cancel from "@mui/icons-material/Cancel";
import {
  AccessTime,
  Call,
  FactCheckOutlined,
  PlaylistAdd,
} from "@mui/icons-material";

import formatPhoneNumber from "../../../utils/formatPhoneNumber";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

import { ANALYTICS_EVENT } from "../../../config/analytics.config";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minHeight: 360,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, bgcolor: "primary.main" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <Cancel fontSize="large" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface RootProps {
  open: boolean;
  handleClose: () => void;
  planName: string;
}

export default function CallAgentDialog({
  open,
  handleClose,
  planName,
}: RootProps) {
  const analytics = useRudderStackAnalytics();

  const Phone = useAppSelector((state) => state.Affiliate.Phone);
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  React.useEffect(() => {
    if (!analytics || !QuoteID || !Phone?.PhoneNumber) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.CALL_AGENT_DIALOG_OPENED, {
      quote_id: QuoteID,
      plan_name: planName,
      phone_number: Phone?.PhoneNumber,
    });

    return () => {
      analytics?.track(ANALYTICS_EVENT.CALL_AGENT_DIALOG_CLOSED, {
        quote_id: QuoteID,
        plan_name: planName,
        phone_number: Phone?.PhoneNumber,
      });
    };
  }, [analytics, QuoteID, planName, Phone?.PhoneNumber]);

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="call-agent-dialog"
      open={open}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <CustomDialogTitle id="call-agent-dialog-title" onClose={handleClose}>
        <Typography variant="h6" fontWeight={600} color="#fff">
          Enrollment
        </Typography>
      </CustomDialogTitle>
      <Grid container flexDirection="column" justifyContent="center" p={2}>
        <List sx={{ textAlign: "left" }}>
          <ListItem>
            <ListItemText>
              <Typography fontWeight={500}>
                Please call one of our licensed insurance agents to begin this
                enrollment!
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Call />
            </ListItemIcon>
            <ListItemButton disableGutters href={`tel:${Phone?.PhoneNumber}`}>
              <Typography>
                <b>Phone:</b>{" "}
                <u>{formatPhoneNumber(Phone?.PhoneNumber ?? "")}</u> TTY 711
              </Typography>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AccessTime />
            </ListItemIcon>
            <ListItemText>
              <Typography>
                <b>Hours of Operation:</b> {Phone?.PhoneNumberAvailability.Days}{" "}
                {Phone?.PhoneNumberAvailability.Hours}{" "}
                {Phone?.PhoneNumberAvailability.Timezone}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PlaylistAdd />
            </ListItemIcon>
            <ListItemText>
              <Typography>
                <b>Plan:</b> {planName}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FactCheckOutlined />
            </ListItemIcon>
            <ListItemText>
              <Typography>
                <b>Quote ID:</b> {QuoteID}
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
    </CustomDialog>
  );
}
