import * as React from "react";
import { createSelector } from "@reduxjs/toolkit";
import { useAppSelector } from "../../../redux/hooks";
import {
  useGetMedSuppPlanDetailsQuery,
  useGetMedSuppQuoteQuery,
} from "../../../redux/services/API";

import { Button, Skeleton, Typography } from "@mui/material";

import MedSuppCard from "../PlanCards/MedSupp.card";
import MedSuppQuestionsDialog from "../MedSuppQuestionsDialog";
import InformationDialog from "../../shared/InformationDialog";

import {
  GENDER_OPTIONS,
  MED_SUPP_PLANS,
  MedSuppQuote,
} from "../../../types/MedSupp.types";

import MedSuppDiscountMarkdown from "../../../assets/med-supp-discounts.md";

import useNavigateParams from "../../../utils/useNavigateParams";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";
import { PLAN_TABS_INFO } from "../Compare.layout";

const emptyArray: MedSuppQuote[] = [];

interface RootProps {
  isPending: boolean;
}

export default function MedSuppContainer({ isPending }: RootProps) {
  const navigate = useNavigateParams();
  const analytics = useRudderStackAnalytics();

  const { MedSuppQuestionAnswers, QuoteID } = useAppSelector(
    (state) => state.Quote
  );
  const PlanFilters = useAppSelector((state) => state.Plan.MedSupp.PlanFilters);
  const { Carriers, Premium } = PlanFilters;

  const [openQuestionDialog, setOpenQuestionDialog] = React.useState(false);
  const [openInformationDialog, setOpenInformationDialog] =
    React.useState(false);

  React.useEffect(() => {
    if (!MedSuppQuestionAnswers.ZipCode) {
      setOpenQuestionDialog(true);
    }
  }, [MedSuppQuestionAnswers]);

  const allFilters = React.useMemo(() => {
    const { Carriers, Premium } = PlanFilters;

    let allFilters: Array<{ type: string; value: any }> = [
      {
        type: "PlanType",
        value: PLAN_TABS_INFO[2].id,
      },
    ];

    for (let carrier of Carriers) {
      allFilters.push({
        type: "CarrierName",
        value: carrier,
      });
    }

    allFilters.push({
      type: "Premium",
      value: `${Premium[0]}-${Premium[1]}`,
    });

    return allFilters;
  }, [PlanFilters]);

  const filterPlanData = React.useMemo(() => {
    return createSelector(
      (res: MedSuppQuote[]) => res,
      (data: MedSuppQuote[]) => {
        if (!data) {
          return [];
        }

        analytics?.track(ANALYTICS_EVENT.SEARCH_STARTED, {
          quote_id: QuoteID,
          search_type: "MED_SUPP_PLANS",
          search_keyword: "",
          search_filters: allFilters,
          search_sorts: [{ type: "RANK", value: "desc" }],
          search_meta: [
            {
              type: "page",
              value: 1,
            },
          ],
        });

        let filterData = data;

        const carrierFilters: string[] = Carriers;

        filterData =
          carrierFilters.length > 0
            ? filterData.filter((plan) => {
                const fieldFilter = plan.company_base.name;
                return carrierFilters.includes(fieldFilter);
              })
            : filterData;

        filterData = filterData.filter((plan) => {
          let monthlyPremium = plan.rate.month / 100.0;

          return monthlyPremium >= Premium[0] && monthlyPremium <= Premium[1];
        });

        analytics?.track(ANALYTICS_EVENT.SEARCH_RESULTS_RETURNED, {
          quote_id: QuoteID,
          search_type: "MED_SUPP_PLANS",
          search_keyword: "",
          search_filters: allFilters,
          search_sorts: [{ type: "RANK", value: "desc" }],
          search_meta: [
            {
              type: "page",
              value: 1,
            },
          ],
          search_result_count: filterData.length,
        });

        return filterData;
      }
    );
  }, [Carriers, Premium]);

  const {
    filterData: planData,
    isFetching: isLoadingPlans,
    isUninitialized,
  } = useGetMedSuppQuoteQuery(
    {
      ZipCode: MedSuppQuestionAnswers.ZipCode,
      Plan: MedSuppQuestionAnswers.Plan as MED_SUPP_PLANS,
      Age: MedSuppQuestionAnswers.Age as number,
      Gender: MedSuppQuestionAnswers.Gender as GENDER_OPTIONS,
      Tobacco: MedSuppQuestionAnswers.Tobacco as number,
      EffectiveDate: MedSuppQuestionAnswers.EffectiveDate,
    },
    {
      skip: !MedSuppQuestionAnswers || !MedSuppQuestionAnswers.ZipCode,
      selectFromResult: (result) => ({
        ...result,
        filterData:
          result.data?.data !== undefined
            ? filterPlanData(result.data.data)
            : emptyArray,
      }),
    }
  );

  const { data: planDetails, isFetching: isLoadingPlanDetails } =
    useGetMedSuppPlanDetailsQuery(
      MedSuppQuestionAnswers.Plan as MED_SUPP_PLANS,
      { skip: !MedSuppQuestionAnswers.Plan }
    );

  const handleDetails = (plan: MedSuppQuote, index: number) => {
    analytics?.track(ANALYTICS_EVENT.MED_SUPP_PLAN_DETAILS_CLICKED, {
      quote_id: QuoteID,
      plan: plan.plan,
      carrier_name: plan.company_base.name_full,
    });

    analytics?.track(ANALYTICS_EVENT.SEARCH_RESULT_CLICKED, {
      quote_id: QuoteID,
      search_type: "MED_SUPP_PLANS",
      search_keyword: "",
      search_filters: allFilters,
      search_sorts: [{ type: "RANK", value: "desc" }],
      search_meta: [
        {
          type: "page",
          value: 1,
        },
      ],
      search_result_count: planData.length,
      search_result_position_clicked: index,
      search_result_clicked_id: `${plan.company_base.name_full}-${plan.plan}`,
    });

    navigate(`/input/compare/medSupp/${plan.key}`);
  };

  const handleQuestionDialogClose = () => {
    setOpenQuestionDialog(false);
  };

  const handleInformationDialogClose = () => {
    setOpenInformationDialog(false);
  };

  const handleInfoClick = (id: string) => {
    console.log(id);
    setOpenInformationDialog(true);
  };

  const handleEditInformationClick = () => {
    setOpenQuestionDialog(true);

    analytics?.track(ANALYTICS_EVENT.MED_SUPP_QUESTIONS_CLICKED, {
      quote_id: QuoteID,
    });
  };

  return (
    <>
      {isUninitialized && (
        <Typography variant="h6" fontWeight={600}>
          Please answer the questions to find plans in your area
        </Typography>
      )}

      <Button
        onClick={() => {
          handleEditInformationClick();
        }}
      >
        {isUninitialized
          ? "Click Here to Answer Questions"
          : "Edit Your Information"}
      </Button>

      {isLoadingPlans || isLoadingPlanDetails || isPending ? (
        <Skeleton
          variant="rectangular"
          sx={{ width: { xs: 350, md: 720 }, height: 420 }}
        />
      ) : (
        planData &&
        planDetails?.data &&
        planData.map((plan, index) => {
          return (
            <MedSuppCard
              id={plan.key}
              key={plan.key}
              info={plan}
              details={planDetails.data}
              handleDetails={(plan: MedSuppQuote) => {
                handleDetails(plan, index);
              }}
              handleInfoClick={handleInfoClick}
            />
          );
        })
      )}

      {openQuestionDialog && (
        <MedSuppQuestionsDialog
          open={openQuestionDialog}
          handleCancel={handleQuestionDialogClose}
          handleClose={handleQuestionDialogClose}
        />
      )}
      {openInformationDialog && (
        <InformationDialog
          open={openInformationDialog}
          handleClose={handleInformationDialogClose}
          title="Discounts"
          markdownFile={MedSuppDiscountMarkdown}
        />
      )}
    </>
  );
}
