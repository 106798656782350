export interface DrxEnrollmentResponse {
  Url: string;
  Parameters: string;
  Message: string;
  EnrollActionType: DrxEnrollAction;
  IntegrationType?: DrxIntegration;
  InternalPlanID: number;
  FormID: string;
  Method: string;
}

enum DrxEnrollAction {
  URL = 1,
  MESSAGE = 2,
  BOTH = 3,
}

enum DrxIntegration {
  INLINE = 0,
  IFRAME = 1,
  POPOUT = 2,
}

export interface ApplicationStatus {
  Plan: {
    _id: string;
    ContractID: string;
    PlanID: string;
    SegmentID: string;
    PlanYear: number;
    DrxID: string;
    PlanName: string;
    PlanType: number;
    PlanSubType: string;
    CarrierSubsidiary: number;
  };
  Carrier: {
    _id: string;
    SubsidiaryID: number;
    ParentCarrierID: number;
    OrgName: string;
    MarketingName: string;
  };
  ApplicationID: string;
  Status: ENROLLMENT_STATUS;
  UpdatedAt: string;
  ConfirmationID: string;
  SubmissionTime: string;
  QuotedMonthlyPremium: number;
  EffectiveDate: string;
  SignatureDate: string;
}

export interface FormRootProps {
  handleNavigate: (id: string) => void;
}

interface Validation {
  required?:
    | boolean
    | {
        value: boolean;
        message: string;
      };
  min?:
    | number
    | {
        value: number;
        message: string;
      };
  max?:
    | number
    | {
        value: number;
        message: string;
      };
  minLength?:
    | number
    | {
        value: number;
        message: string;
      };
  maxLength?:
    | number
    | {
        value: number;
        message: string;
      };
  pattern?:
    | string
    | {
        value: string;
        message: string;
      };
  minDate?: string;
  maxDate?: string;
}

export interface HintData {
  Type: "popover" | "dialog";
  Title?: string;
  Content: string;
  Clickable: "text" | "icon";
}

export interface RequiredIf {
  fields: string[];
  value?: string;
}

export interface FieldInput {
  id: string;
  Tag: "input";
  Type:
    | "text"
    | "dropdown"
    | "radio"
    | "radio-cards"
    | "enrollment-periods"
    | "doctor-finder"
    | "checkbox"
    | "date"
    | "phone"
    | "medicare-number"
    | "us-states"
    | "address-autocomplete"
    | "array-text"
    | "medicine-finder"
    | "specialist-finder";
  Label: string;
  Placeholder?: string;
  InitialValue?: string | number | boolean;
  Required: boolean;
  RequiredIf?: RequiredIf;
  Validation?: Validation;
  Width: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  OptionHasFollowup?: boolean;
  Options?: Array<{
    label: string;
    value: string;
    Followup?: Field[];
  }>;
  Vertical?: boolean;
  Hint?: {
    Title: string;
    Content: string;
  };
  LimitOptions?: number;
  ReadOnly?: boolean;
  AddressFields?: {
    Address1: string;
    City: string;
    State: string;
    ZipCode: string;
    County?: string;
  };
}

export interface FieldText {
  id: string;
  Tag: "text";
  Type: "disclosure" | "header" | "link";
  Content: string;
  Width: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  Hint?: {
    Title: string;
    Content: string;
  };
}

export type Field = FieldInput | FieldText;

export interface SpecialistInformation {
  ProviderID: number;
  LocationID: number;
  Name: string;
  PhoneNumber: string;
  Address1: string;
  Address2?: string;
  City: string;
  State: string;
  ZipCode: string;
  NPI: string;
}

export enum FORM_SECTION_TYPE {
  ARRAY = "ARRAY",
  STANDARD = "STANDARD",
}

export interface StandardSection {
  id: string;
  Title: string;
  Optional: boolean;
  Accordion: boolean;
  Fields: Field[];
  Type: FORM_SECTION_TYPE.STANDARD;
}

export interface ArraySection {
  id: string;
  Title: string;
  Optional: boolean;
  Accordion: boolean;
  Fields: Field[];
  Type: FORM_SECTION_TYPE.ARRAY;
  MaxEntries?: number;
}

export type Section = StandardSection | ArraySection;

export interface DynamicFormPage {
  id: string;
  Title: string;
  Sections: Section[];
}

export interface Metadata {
  FilingNumber: string;
  DateUpdated: string;
}

export interface EnrollmentFormData {
  Carrier: string;
  PlanType: number;
  Year: number;
  PageMetadata: Metadata;
  Pages: DynamicFormPage[];
}

export interface EnrollmentFormResponse {
  carrier: {
    _id: string;
    SubsidiaryID: number;
    ParentCarrierID: number;
    OrgName: string;
    MarketingName: string;
  };
  formType: "INTERNAL" | "DRX" | "CALL_AGENT";
  form: {
    _id: string;
    FormID: number;
    Metadata: Metadata;
    Pages: DynamicFormPage[];
  } | null;
}

/** Static for now (Can be requested from API in future) */
interface EnrollmentStepsType {
  [key: string]: {
    title: string;
    step: number;
    ignoreOnStepList?: boolean;
  };
}

export const EnrollmentSteps: EnrollmentStepsType = {
  "applicant-information": {
    title: "Applicant Information",
    step: 1,
  },
  "contact-information": {
    title: "Contact Information",
    step: 2,
  },
  "medicare-information": {
    title: "Medicare Information",
    step: 3,
  },
  "eligibility-questions": {
    title: "Eligibility Questions",
    step: 4,
  },
  review: {
    title: "Applicant Review",
    step: 5,
  },
  "application-effective-date": {
    title: "Application Effective Date",
    step: 6,
  },
  signature: {
    title: "Application Signature",
    step: 7,
  },
  confirmation: {
    title: "Enrollment Confirmation",
    step: 8,
    ignoreOnStepList: true,
  },
};

/** React-Hooks-Form doesn't allow booleans with no inital value */
export const YES_NO = [
  {
    label: "Yes",
    value: "YES",
  },
  {
    label: "No",
    value: "NO",
  },
];

export const BANK_ACCOUNT_TYPES = [
  {
    label: "Checking",
    value: "CHECKING",
  },
  {
    label: "Savings",
    value: "SAVINGS",
  },
];

type id_string = { [id: string]: string };
export const ENROLLMENT_PERIODS_WITH_DATE: id_string = {
  RET: "Medicare Start Date",
  MOV: "Date you Moved to new Address",
  RUS: "Date you Established Residency",
  INC: "Date Released from Jail",
  LAW: "Date you Established Residency",
  LTC: "Date you Moved to new Address",
  LEC: "End Date of Previous Coverage",
  LCC: "End Date of Previous Coverage",
  EOC: "End Date of Previous Coverage",
  MYT: "End Date of Previous Coverage",
  PAC: "End Date of Previous Coverage",
  SNP: "End Date of Previous Coverage",
  MCD: "End Date of Previous Coverage",
  NLS: "End Date of Previous Coverage",
  DIF: "End Date of Previous Coverage",
  PAP: "Date of Coverage you are Leaving",
};

export const COMMON_ENROLLMENT_PERIODS = [
  {
    label:
      "I am enrolling between October 15-December 17 during the current Annual Enrollment Period.",
    value: "AEP",
    EffDate: false,
  },
  {
    label: "I'm new to Medicare.",
    value: "NEW",
    EffDate: false,
  },
  {
    label: "I had Medicare prior to now, but I'm turning 65.",
    value: "MRD",
    EffDate: false,
  },
  {
    label: "I'm in a Medicare Advantra Plan and want to make a change.", // I'm in a Medicare Advantage Plan and have had Medicare for less than 3 months. I want to make a change.
    value: "OEP",
    EffDate: false,
  },
  {
    label: "I'm enrolling in a 5-star Medicare plan.",
    value: "LPI",
    EffDate: false,
  },
  {
    label:
      "I live in a long-term care facility, like a nursing home or a rehabilitation hospital.",
    value: "LT2",
    EffDate: false,
  },
  {
    label:
      "I lost my Special Needs Plan because I no longer have a condition required for that plan.",
    value: "MDE",
    EffDate: false,
  },
  {
    label:
      "I'm in a plan that's had a star rating of less than 3 stars for the last 3 years. I want to join a plan with a star rating of 3 stars or higher.",
    value: "REC",
    EffDate: false,
  },
];

export const OTHER_ENROLLMENT_PERIODS = [
  {
    label:
      "I already have Hospital (Part A) and recently signed up for Medical (Part B). I want to join a Medicare Advantage Plan.",
    value: "ICE",
    EffDate: false,
  },
  {
    label:
      "I'm new to Medicare, and I was notified about getting Medicare after my Part A and/or Part B coverage started.",
    value: "RET",
    EffDate: true,
  },
  {
    label:
      "I moved to a new address that's outside my current plan's service area, or I recently moved and this plan is a new option for me.",
    value: "MOV",
    EffDate: true,
  },
  {
    label: "I moved back to the U.S. after living outside the country.",
    value: "RUS",
    EffDate: true,
  },
  {
    label: "I was released from jail.",
    value: "INC",
    EffDate: true,
  },
  {
    label: "I recently got lawful presence status in the U.S.",
    value: "LAW",
    EffDate: true,
  },

  {
    label:
      "I recently moved out of a long-term facility, like a nursing home or a rehabilitation hospital.",
    value: "LTC",
    EffDate: true,
  },
  {
    label:
      "I left my coverage from my employer or union (including COBRA coverage).",
    value: "LEC",
    EffDate: true,
  },
  {
    label:
      "I lost other, non-Medicare drug coverage that's as good as Medicare drug coverage (creditable coverage, or my other, non-Medicare coverage changed and is no longer considered creditable).",
    value: "LCC",
    EffDate: true,
  },
  {
    label:
      "I lost my coverage because my plan no longer covers the area that I live or it ended its contract with Medicare.",
    value: "EOC",
    EffDate: true,
  },
  {
    label:
      "I lost my coverage because Mediare ended its contract with my plan. I got a letter from Medicare saying I could join another plan.",
    value: "MYT",
    EffDate: true,
  },
  {
    label:
      "I dropped my coverage in a PACE (Programs of All-Inclusive Care for the Elderly) plan.",
    value: "PAC",
    EffDate: true,
  },
  {
    label: "THIS ONE DOESNT HAVE QUESTION",
    value: "SNP",
    EffDate: true,
  },

  {
    label:
      "I have both Medicare and Medicaid, my state helps pay for my Medicare premiums, or I get Extra Help paying my Medicare drug coverage.",
    value: "MCD",
    EffDate: true,
  },
  {
    label:
      "I recently had a change in my Medicaid (newly got Medicaid, had a change in my level or Medicaid, or lost Medicaid).",
    value: "NLS",
    EffDate: true,
  },
  {
    label:
      "I recently had a change in my Extra Help paying for my drug costs (newly got Extra Help, had a change in my level of Extra Help, or lost Extra Help).",
    value: "DIF",
    EffDate: true,
  },
  {
    label:
      "I was enrolled in a plan by Medicare (or my state) and I want to choose a different plan.",
    value: "PAP",
    EffDate: true,
  },
  {
    label:
      "I'm in a State Pharmaceutical Assistance Program, or I'm losing help from a State Pharmaceutical Assistance Program.",
    value: "12G",
    EffDate: false,
  },
  {
    label:
      "I dropped a Medicare Supplement Insurance (Medigap) policy when I first joined a Medicare Advantage Plan. It's been less than 12 months since I left my Medigap polcy. I wanted to switch back to the original Medicare so I can go back to my Medigap policy, and I'm joining a Drug Plan (Part D).",
    value: "DST",
    EffDate: false,
  },
  {
    label:
      "I was affected by an emergency or a major disaster (as declared by the Federal Emergency Management Agency, or by Federal, my state, or my local government). One of the other statements on this page applied to me, but I was unable to make my request because of the disaster.",
    value: "12J",
    EffDate: false,
  },
  {
    label:
      "I joined a Medicare Advantage Plan with drug coverage when I turned 65. It's been less than 12 months since I joined this plan. I want to switch to Original Medicare, and I'm joining a Drug Plan.",
    value: "5ST",
    EffDate: false,
  },

  {
    label: "Other",
    value: "OTH",
    EffDate: false,
  },
];

export const STATES = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Washington, D.C.", value: "DC" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const ACCESSIBLE_OPTIONS = [
  { label: "Audio", value: "Audio" },
  { label: "Large Print", value: "Large Print" },
  {
    label: "Accessible Screen Reader PDF",
    value: "Accessible Screen Reader PDF",
  },
  { label: "Oral Over the Phone", value: "Oral Over the Phone" },
  { label: "Braille", value: "Braille" },
];

export const HCHS_ACCESSIBLE_OPTIONS = [
  { label: "Large Print", value: "Large Print" },
  { label: "Braille", value: "Braille" },
];

export const RELATIONSHIP_OPTIONS = [
  {
    value: "son",
    label: "Son",
  },
  {
    value: "daughter",
    label: "Daughter",
  },
  {
    value: "brother",
    label: "Brother",
  },
  {
    value: "sister",
    label: "Sister",
  },
  {
    value: "caregiver",
    label: "Caregiver",
  },
  {
    value: "SOA",
    label: "SOA",
  },
];

export const RACE_OPTIONS = [
  {
    value: "300",
    label: "American Indian or Alaska Native",
  },
  {
    value: "401",
    label: "Asian Indian",
  },
  {
    value: "201",
    label: "Black or African American",
  },
  {
    value: "411",
    label: "Chinese",
  },
  {
    value: "421",
    label: "Filipino",
  },
  {
    value: "521",
    label: "Guamanian or Chamorro",
  },
  {
    value: "431",
    label: "Japanese",
  },
  {
    value: "441",
    label: "Korean",
  },
  {
    value: "501",
    label: "Native Hawaiian",
  },
  {
    value: "499",
    label: "Other Asian",
  },
  {
    value: "599",
    label: "Other Pacific Islander",
  },
  {
    value: "511",
    label: "Samoan",
  },
  {
    value: "451",
    label: "Vietnamese",
  },
  {
    value: "101",
    label: "White",
  },
  {
    value: "999",
    label: "I choose not to answer",
  },
];
export const RACE_OPTIONS_CONVERT: { [code: string]: string } = {
  "300": "American Indian or Alaska Native",
  "401": "Asian Indian",
  "201": "Black or African American",
  "411": "Chinese",
  "421": "Filipino",
  "521": "Guamanian or Chamorro",
  "431": "Japanese",
  "441": "Korean",
  "501": "Native Hawaiian",
  "499": "Other Asian",
  "599": "Other Pacific Islander",
  "511": "Samoan",
  "451": "Vietnamese",
  "101": "White",
  "999": "I choose not to answer",
};

export const ETHNICITY_OPTIONS = [
  {
    value: "NA",
    label: "Not of Hispanic, Latino or Spanish Origin",
  },
  {
    value: "PR",
    label: "Puerto Rican",
  },
  {
    value: "OTH",
    label: "Another Hispanic, Latino or Spanish Origin",
  },
  {
    value: "MX",
    label: "Mexican, Mexican American, Chicano/a",
  },
  {
    value: "CUB",
    label: "Cuban",
  },
  {
    value: "NR",
    label: "I choose not to answer",
  },
  {
    value: "OTHER",
    label: "Other",
  },
];

export const ETHNICITY_OPTIONS_CONVERT: { [code: string]: string } = {
  NA: "Not of Hispanic, Latino or Spanish Origin",
  PR: "Puerto Rican",
  OTH: "Another Hispanic, Latino or Spanish Origin",
  MX: "Mexican, Mexican American, Chicano/a",
  CUB: "Cuban",
  NR: "I choose not to answer",
  OTHER: "Other",
};

export const GENDER_IDENTITY_OPTIONS = [
  {
    label: "Woman",
    value: "1",
  },
  {
    label: "Man",
    value: "2",
  },
  {
    label: "Non-binary",
    value: "3",
  },
  {
    label: "I use a different term",
    value: "4",
  },
  {
    label: "I choose not to answer",
    value: "5",
  },
];

export const SELF_IDENTITY_OPTIONS = [
  {
    label: "Lesbian or gay",
    value: "1",
  },
  {
    label: "Straight",
    value: "2",
  },
  {
    label: "Bisexual",
    value: "3",
  },
  {
    label: "I use a different term",
    value: "4",
  },
  {
    label: "Not sure",
    value: "5",
  },
  {
    label: "I choose not to answer",
    value: "6",
  },
];

export enum ENROLLMENT_STATUS {
  ERROR = "ERROR",
  PENDING = "PENDING",
  INELIGIBLE = "INELIGIBLE",
  ENROLL = "ENROLL",
  DISENROLL = "DISENROLL",
  CREATED = "CREATED",
  DENIED = "DENIED",
  GENERATED = "GENERATED",
  WAITING_FOR_PUSH = "WAITING_FOR_PUSH",
}
