import { PlanType } from "../types/Plan.types";

export function findCarrier(carrier: string) {
  if (/aetna/i.test(carrier)) {
    return "aetna";
  }

  if (/humana/i.test(carrier)) {
    return "humana";
  }

  if (/(hcsc|hisc|ghs)/i.test(carrier)) {
    return "bcbs";
  }

  return "";
}

export function findPlanType(planType: number) {
  switch (planType) {
    case PlanType.MAPD:
      return "MAPD";
    case PlanType.PDP:
      return "PDP";
    case PlanType.MA:
      return "MA";
    default:
      return "";
  }
}
