import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  setApplicationID,
  submitForm,
  submitFormSuccess,
} from "../reducers/Enrollment";
import {
  setAffiliateCode,
  setAffiliateData,
  setLoadingData,
} from "../reducers/Affiliate";

import {
  DoctorNetwork,
  DoctorsResponse,
  SelectedDoctor,
} from "../../types/Doctor.types";
import {
  ApplicationStatus,
  DrxEnrollmentResponse,
  EnrollmentFormResponse,
} from "../../types/Enrollment.types";
import { Location } from "../../types/Location.types";

import {
  API_RESPONSE,
  AzureSearchAddressResponse,
  US_STATE,
} from "../../types/Global.types";
import {
  Medicine,
  MedicineDetails,
  SelectedMedicine,
} from "../../types/Medicine.types";
import {
  Pharmacy,
  PharmacySearchResponse,
  SelectedPharmacy,
} from "../../types/Pharmacy.types";
import {
  MedSuppQuestion,
  Plan,
  PlanFavorite,
  PlanResponse,
  PlanType,
} from "../../types/Plan.types";
import {
  HealthConditionCategory,
  HealthConditionMedication,
  MED_SUPP_PLANS,
  MedSuppPlanDetails,
  MedSuppQuote,
  MedSuppQuoteParameters,
} from "../../types/MedSupp.types";
import { CreateQuoteResponse, QuoteAttribute } from "../../types/Quote.types";
import { AffiliateResponse } from "../../types/Affiliate.types";

import {
  setDoctorNetworks,
  setMedSuppCarriers,
  setMedSuppPlanNames,
  setPlanMetaData,
} from "../reducers/Plan";
import {
  addDoctor,
  addMedicine,
  addPlan,
  removeDoctor,
  removeMedicine,
  removePlan,
  setAnswerMedSuppQuestions,
  setLocation,
  setPharmacy,
  setQuote,
  setQuoteID,
  setSessionID,
} from "../reducers/Quote";
import { setAzureMapsKey } from "../reducers/Utils";

import { API_URL, AZURE_FUNCTION_KEY } from "../../config/global.config";

const WEBSITE_KEY = process.env.REACT_APP_WEBSITE_KEY;

export const API = createApi({
  reducerPath: "API",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: [
    "Quote",
    "Doctors",
    "Enroll",
    "EnrollForm",
    "Location",
    "Medicine",
    "MedSupp",
    "Pharmacy",
    "Plan",
    "AddressAutocomplete",
    "Attributes",
  ],
  endpoints: (build) => ({
    loadAffiliateData: build.mutation<
      AffiliateResponse,
      // Object allows more information to be passed into the body later
      { Code: string }
    >({
      query: ({ Code }) => ({
        url: `/affiliate/${Code}`,
        method: "POST",
        headers: {
          "x-website-key": WEBSITE_KEY,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setAffiliateCode(args.Code));
          dispatch(setAffiliateData(data));
          dispatch(setLoadingData(false));
        } catch {
          dispatch(setAffiliateCode("vm-default"));
        }
      },
    }),
    createQuote: build.mutation<
      CreateQuoteResponse,
      { Location: Location; BrokerNPN: string | null; Affiliate: string }
    >({
      query: ({ Location, BrokerNPN, Affiliate }) => ({
        url: `/lmpp/session/new`,
        method: "POST",
        body: {
          ...Location,
          BrokerNPN,
          Affiliate,
        },
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setQuoteID(data.QuoteID));
          dispatch(setSessionID(data.SessionID));

          if (data.Location) {
            dispatch(setLocation(data.Location));
          }
        } catch {
          throw Error("QuoteID Generation Failed");
        }
      },
    }),
    loadQuote: build.query<any, string>({
      providesTags: ["Quote"],
      query: (QuoteID) => ({
        url: `/quote/${QuoteID}`,
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setQuote(data));
        } catch {}
      },
    }),
    emailQuote: build.mutation<
      API_RESPONSE<{ success: boolean; email: string }>,
      { QuoteID: string; Email: string; AffiliateCode: string }
    >({
      query: ({ QuoteID, Email, AffiliateCode }) => ({
        url: `/quote/${QuoteID}/email`,
        method: "POST",
        headers: {
          "x-website-key": WEBSITE_KEY,
        },
        body: {
          Email,
          AffiliateCode,
        },
      }),
    }),
    getLocationInfo: build.query<API_RESPONSE<Location[]>, string>({
      providesTags: ["Location"],
      query: (ZipCode) => ({
        url: `/lmpp/location/${ZipCode}`,
      }),
    }),
    getDoctors: build.query<
      DoctorsResponse,
      {
        zipCode: string;
        radiusInMiles: number;
        page: number;
        providerName: string;
        coordinates: string;
        filters?: string[];
      }
    >({
      providesTags: ["Doctors"],
      query: ({
        zipCode,
        radiusInMiles,
        page,
        providerName,
        coordinates,
        filters,
      }) => ({
        url: `/doctors`,
        params: {
          zipCode,
          radiusInMiles,
          page,
          per: 5,
          providerName,
          coordinates,
          ...(filters && { filters }),
        },
      }),
    }),
    getDoctorsInNetwork: build.query<
      DoctorsResponse,
      {
        zipCode: string;
        radiusInMiles: number;
        page: number;
        network: string;
        providerName?: string;
        coordinates: string;
        filters?: string[];
        providerType?: string;
      }
    >({
      providesTags: (_result, _error, arg) =>
        arg
          ? [{ type: "Doctors" as const, id: arg.network }, "Doctors"]
          : ["Doctors"],
      query: ({
        zipCode,
        radiusInMiles,
        page,
        network,
        providerName,
        coordinates,
        filters,
        providerType,
      }) => ({
        url: `/doctors/networks/${network}`,
        params: {
          zipCode,
          radiusInMiles,
          page,
          per: 5,
          providerName,
          coordinates,
          providerType,
          ...(filters && { filters }),
        },
      }),
    }),
    saveDoctor: build.mutation<
      API_RESPONSE<{ success: boolean }>,
      { QuoteID: string; Doctor: SelectedDoctor }
    >({
      query: ({ QuoteID, Doctor }) => ({
        url: `/quote/${QuoteID}/doctor`,
        method: "POST",
        body: Doctor,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          dispatch(addDoctor(arg.Doctor));
          await queryFulfilled;
        } catch {}
      },
    }),
    deleteDoctor: build.mutation<
      API_RESPONSE<{ success: boolean }>,
      { QuoteID: string; DoctorID: number }
    >({
      query: ({ QuoteID, DoctorID }) => ({
        url: `/quote/${QuoteID}/doctor/${DoctorID}`,
        method: "DELETE",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          dispatch(removeDoctor(arg.DoctorID));
          await queryFulfilled;
        } catch {}
      },
    }),
    getInitialMedicines: build.query<API_RESPONSE<Medicine[]>, void>({
      providesTags: ["Medicine"],
      query: () => ({
        url: "/lmpp/medicines/initial",
      }),
    }),
    saveMedicine: build.mutation<
      API_RESPONSE<SelectedMedicine>,
      {
        SessionID: string;
        NDC: string;
        MetricQuantity: string;
        DaysOfSupply: string;
      }
    >({
      query: ({ SessionID, ...Medicine }) => ({
        url: `/lmpp/session/${SessionID}/medicine`,
        method: "POST",
        body: Medicine,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(addMedicine(data.data));
        } catch {}
      },
      invalidatesTags: ["Plan"],
    }),
    deleteMedicine: build.mutation<
      API_RESPONSE<{ DosageRecordID: number }>,
      {
        SessionID: string;
        DosageRecordID: number;
      }
    >({
      query: ({ SessionID, DosageRecordID }) => ({
        url: `/lmpp/session/${SessionID}/medicine/${DosageRecordID}`,
        method: "DELETE",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
        } finally {
          dispatch(removeMedicine(args.DosageRecordID));
        }
      },
      invalidatesTags: ["Plan"],
    }),
    searchMedicines: build.query<
      API_RESPONSE<Medicine[]>,
      { search: string; page: number }
    >({
      providesTags: ["Medicine"],
      query: ({ search, page }) => ({
        url: "/lmpp/medicines",
        params: {
          search,
          page,
          per: 6,
        },
      }),
    }),
    getMedicineDetails: build.query<
      API_RESPONSE<MedicineDetails>,
      string | number
    >({
      providesTags: (result) =>
        result
          ? [{ type: "Medicine" as const, id: result.data.DrugID }, "Medicine"]
          : ["Medicine"],
      query: (id) => ({
        url: `/lmpp/medicines/${id}`,
      }),
    }),
    savePharmacy: build.mutation<
      API_RESPONSE<SelectedPharmacy>,
      {
        SessionID: string;
        IsMailOrder: boolean;
        PharmacyID: string;
      }
    >({
      query: ({ SessionID, ...Pharmacy }) => ({
        url: `/lmpp/session/${SessionID}/pharmacy`,
        method: "POST",
        body: { ...Pharmacy, PharmacyIDType: 0 },
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setPharmacy(data.data));
        } catch {}
      },
      invalidatesTags: ["Plan"],
    }),
    deletePharmacy: build.mutation<
      void,
      {
        SessionID: string;
        PharmacyRecordID: number;
      }
    >({
      query: ({ SessionID, PharmacyRecordID }) => ({
        url: `/lmpp/session/${SessionID}/pharmacy/${PharmacyRecordID}`,
        method: "DELETE",
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setPharmacy(null));
        } catch {}
      },
      invalidatesTags: ["Plan"],
    }),
    searchPharmacies: build.query<
      PharmacySearchResponse,
      { zipCode: string; radiusInMiles: number; page: number; search?: string }
    >({
      providesTags: ["Pharmacy"],
      query: ({ zipCode, radiusInMiles, page, search }) => ({
        url: "/lmpp/pharmacies",
        params: {
          zip: zipCode,
          radius: radiusInMiles,
          page,
          per: 5,
          search,
        },
      }),
    }),
    getPharmacyByID: build.query<API_RESPONSE<Pharmacy>, string | number>({
      providesTags: (result) =>
        result
          ? [
              { type: "Pharmacy" as const, id: result.data.PharmacyID },
              "Pharmacy",
            ]
          : ["Pharmacy"],
      query: (id) => ({
        url: `/lmpp/pharmacies/${id}`,
      }),
    }),
    savePlan: build.mutation<
      API_RESPONSE<{ success: boolean }>,
      { QuoteID: string; Plan: PlanFavorite }
    >({
      query: ({ QuoteID, Plan }) => ({
        url: `/quote/${QuoteID}/plan`,
        method: "POST",
        body: Plan,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          dispatch(addPlan(arg.Plan));
          await queryFulfilled;
        } catch {}
      },
    }),
    deletePlan: build.mutation<
      API_RESPONSE<{ success: boolean }>,
      { QuoteID: string; PlanID: string }
    >({
      query: ({ QuoteID, PlanID }) => ({
        url: `/quote/${QuoteID}/plan/${PlanID}`,
        method: "DELETE",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          dispatch(removePlan(arg.PlanID));
          await queryFulfilled;
        } catch {}
      },
    }),
    getPlansInArea: build.query<
      PlanResponse,
      {
        SessionID: string;
        zipCode: string;
        fips: string;
        year: number;
        planType: PlanType;
        effectiveDate: string;
        filters?: string;
      }
    >({
      keepUnusedDataFor: 60 * 10, // 10 min
      providesTags: (_result, _error, args) =>
        args
          ? [
              {
                type: "Plan" as const,
                id: args.planType,
              },
            ]
          : ["Plan"],
      query: ({
        SessionID,
        zipCode,
        fips,
        year,
        planType,
        effectiveDate,
        filters,
      }) => ({
        url: `/lmpp/${SessionID}/plans`,
        params: {
          zip: zipCode,
          fips,
          year,
          planType,
          effectiveDate,
          ...(filters && { filters: encodeURI(filters) }),
        },
      }),
      async onQueryStarted({ planType, year }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            setPlanMetaData({
              planYear: year,
              planType: planType.toString(),
              metadata: {
                Carriers: data.meta.carriers,
                PlanSubTypes: data.meta.planSubTypes,
                PlanFeatures: data.meta.planFeatures,
                TotalPlans: data.meta.totalPlans,
              },
            })
          );
        } catch {
          dispatch(
            setPlanMetaData({
              planYear: year,
              planType: planType.toString(),
              metadata: {
                Carriers: [],
                PlanSubTypes: [],
                PlanFeatures: [],
                TotalPlans: 0,
              },
            })
          );
        }
      },
    }),
    getDoctorsNetworks: build.query<
      API_RESPONSE<DoctorNetwork[]>,
      { [key: string]: number }
    >({
      providesTags: (result) =>
        result
          ? [
              {
                type: "Doctors" as const,
                id: result.data.map((doc) => doc.providerId).join(","),
              },
              "Plan",
              "Doctors",
            ]
          : ["Plan", "Doctors"],
      query: (providers) => ({
        url: "/doctors/networks",
        method: "POST",
        body: providers,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          if (Object.keys(args).length === 0) {
            dispatch(setDoctorNetworks([]));
          } else {
            const { data } = await queryFulfilled;
            dispatch(setDoctorNetworks(data.data));
          }
        } catch {}
      },
    }),
    getPlanByID: build.query<
      API_RESPONSE<Plan>,
      {
        SessionID: string;
        PlanID: string;
        Zip: string;
        Fips: string;
        effectiveDate: string;
      }
    >({
      providesTags: (result) =>
        result
          ? [{ type: "Plan" as const, id: result.data.ID }, "Plan"]
          : ["Plan"],
      query: ({ SessionID, PlanID, Zip, Fips, effectiveDate }) => ({
        url: `/lmpp/${SessionID}/plans/${PlanID}`,
        params: {
          zip: Zip,
          fips: Fips,
          effectiveDate,
        },
      }),
    }),
    getMedSuppQuestions: build.query<
      API_RESPONSE<MedSuppQuestion[]>,
      {
        SessionID: string;
        Zip: string;
        Fips: string;
        EffectiveDate: string; // YYYY-MM-DD
      }
    >({
      providesTags: ["MedSupp"],
      query: ({ SessionID, Zip, Fips, EffectiveDate }) => ({
        url: `/lmpp/session/${SessionID}/questions`,
        params: {
          zip: Zip,
          fips: Fips,
          effectiveDate: EffectiveDate,
        },
      }),
    }),
    saveMedSuppAnswers: build.mutation<
      API_RESPONSE<{ success: boolean }>,
      { SessionID: string; Answers: MedSuppQuestion[] }
    >({
      query: ({ SessionID, Answers }) => ({
        url: `/lmpp/session/${SessionID}/questions`,
        method: "POST",
        body: { Answers },
      }),
      invalidatesTags: [
        "MedSupp",
        {
          type: "Plan" as const,
          id: PlanType.MEDSUPP,
        },
      ],
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAnswerMedSuppQuestions(data.data.success));
        } catch {}
      },
    }),
    generateApplicationID: build.query<
      API_RESPONSE<{ ApplicationID: string }>,
      {
        QuoteID: string;
        ContractID: string;
        PlanID: string;
        SegmentID: string;
        PlanYear: number;
        QuotedMonthlyPremium: number;
        State: string;
        NPN?: string;
        LeadSource?: string;
      }
    >({
      providesTags: ["Enroll"],
      query: ({
        QuoteID,
        ContractID,
        PlanID,
        SegmentID,
        PlanYear,
        QuotedMonthlyPremium,
        State,
        NPN,
        LeadSource,
      }) => ({
        url: `/enroll/generate`,
        method: "POST",
        body: {
          QuoteID,
          ContractID,
          PlanID,
          SegmentID,
          PlanYear,
          QuotedMonthlyPremium,
          State,
          NPN,
          LeadSource,
          UserType: "CONSUMER",
        },
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setApplicationID(data.data.ApplicationID));
        } catch {}
      },
    }),
    getEnrollmentFormData: build.query<
      API_RESPONSE<EnrollmentFormResponse>,
      {
        Year: number;
        ContractID: string;
        PlanID: string;
        SegmentID: string;
        UserType: string;
        SnpType?: number;
      }
    >({
      providesTags: (result) =>
        result
          ? [{ type: "EnrollForm", id: result?.data.form?.FormID }]
          : ["EnrollForm"],
      query: ({ Year, ContractID, PlanID, SegmentID, UserType, SnpType }) => ({
        url: `/enroll/form/${Year}/${ContractID}/${PlanID}/${SegmentID}`,
        params: {
          userType: UserType,
          snpType: SnpType,
        },
      }),
      // async onQueryStarted(_args, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data } = await queryFulfilled;

      //     if (data?.data?.carrier?.MarketingName) {
      //       dispatch(setCarrier(data.data.carrier.MarketingName));
      //     }

      //     if (data?.data?.formType === "INTERNAL") {
      //       if (data.data.form?.Metadata) {
      //         dispatch(setMetadata(data.data.form.Metadata));
      //       }
      //     }
      //   } catch {}
      // },
    }),
    getDrxEnrollment: build.query<
      API_RESPONSE<DrxEnrollmentResponse>,
      {
        SessionID: string;
        DrxPlanID: string;
        QuoteID: string;
        UserType: string;
        ContractID: string;
        PlanID: string;
        SegmentID: string;
        PlanYear: number;
        ZipCode: string;
        Fips: string;
        State: string;
        Affiliate: string;
        BrokerNPN?: string;
      }
    >({
      providesTags: ["Enroll"],
      query: ({
        SessionID,
        DrxPlanID,
        QuoteID,
        UserType,
        ContractID,
        PlanID,
        SegmentID,
        PlanYear,
        ZipCode,
        Fips,
        State,
        Affiliate,
        BrokerNPN,
      }) => ({
        url: `/lmpp/${SessionID}/plans/${DrxPlanID}/enroll`,
        method: "POST",
        body: {
          QuoteID,
          UserType,
          ContractID,
          PlanID,
          SegmentID,
          PlanYear,
          ZipCode,
          Fips,
          State,
          Affiliate,
          BrokerNPN,
        },
      }),
    }),
    postEnrollmentData: build.mutation<any, Partial<any>>({
      query: (body) => ({
        url: "enroll",
        method: "POST",
        body,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(submitFormSuccess());
        } catch {
          dispatch(submitForm(false));
        }
      },
    }),
    getApplicationStatus: build.query<API_RESPONSE<ApplicationStatus>, string>({
      providesTags: ["Enroll"],
      query: (ApplicationID) => ({
        url: `/enroll/status/${ApplicationID}`,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setApplicationID(data.data.ApplicationID));
        } catch {}
      },
    }),
    /**
     * Azure Map APIs
     */
    addressAutocomplete: build.query<
      AzureSearchAddressResponse,
      { query: string; lat: number; lon: number; AzureMapsKey: string }
    >({
      providesTags: ["AddressAutocomplete"],
      query: ({ query, lat, lon, AzureMapsKey }) => ({
        url: "https://atlas.microsoft.com/search/address/json",
        params: {
          "api-version": 1.0,
          "subscription-key": AzureMapsKey,
          language: "en-US",
          countrySet: "US",
          type: "Street",
          typeahead: true,
          query,
          lat,
          lon,
        },
      }),
    }),
    getAzureMapsKey: build.query<{ key: string }, void>({
      query: () => ({
        url: "https://azure.medicare-api.com/api/get_azure_maps_key",
        headers: {
          "x-functions-key": AZURE_FUNCTION_KEY,
        },
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAzureMapsKey(data.key));
        } catch {}
      },
    }),

    /**
     * Med Supp Endpoints
     */
    getMedSuppQuote: build.query<
      API_RESPONSE<MedSuppQuote[], { carriers: string[]; totalPlans: number }>,
      MedSuppQuoteParameters
    >({
      providesTags: ["MedSupp"],
      keepUnusedDataFor: 60 * 10, // 10 min
      query: (quoteParameters) => ({
        url: "/med-supp/quotes",
        method: "POST",
        body: {
          zipCode: quoteParameters.ZipCode,
          plan: quoteParameters.Plan,
          age: quoteParameters.Age,
          gender: quoteParameters.Gender,
          tobacco: quoteParameters.Tobacco,
          effectiveDate: quoteParameters.EffectiveDate,
        },
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data.meta?.carriers) {
            dispatch(setMedSuppCarriers(data.meta.carriers));
          }
        } catch {}
      },
    }),
    getMedSuppPlanNames: build.query<
      API_RESPONSE<MED_SUPP_PLANS[]>,
      { ZipCode: string; State: US_STATE }
    >({
      providesTags: ["MedSupp"],
      query: ({ ZipCode, State }) => ({
        url: "/med-supp/plans",
        params: {
          zipCode: ZipCode,
          state: State,
        },
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setMedSuppPlanNames(data.data));
        } catch {}
      },
    }),
    getMedSuppPlanDetails: build.query<
      API_RESPONSE<
        MedSuppPlanDetails,
        { planName: string; sections: string[] }
      >,
      string
    >({
      providesTags: ["MedSupp"],
      query: (PlanName) => ({
        url: `/med-supp/plans/${PlanName}`,
      }),
    }),
    getMedSuppQuoteKey: build.query<
      API_RESPONSE<MedSuppQuote, { logKey: string }>,
      string
    >({
      providesTags: ["MedSupp"],
      query: (QuoteKey) => ({
        url: `/med-supp/quotes/${QuoteKey}`,
      }),
    }),
    getHealthConditions: build.query<
      API_RESPONSE<HealthConditionCategory[]>,
      US_STATE
    >({
      providesTags: ["MedSupp"],
      query: (State) => ({
        url: `/med-supp/health-conditions`,
        params: {
          state: State,
        },
      }),
    }),
    getHealthConditionMedications: build.query<
      API_RESPONSE<HealthConditionMedication[]>,
      US_STATE
    >({
      providesTags: ["MedSupp"],
      query: (State) => ({
        url: `/med-supp/health-conditions/medications`,
        params: {
          state: State,
        },
      }),
    }),
    saveQuoteAttributes: build.mutation<
      API_RESPONSE<{ success: boolean }>,
      { QuoteID: string; Attributes: QuoteAttribute[] }
    >({
      invalidatesTags: ["Attributes"],
      query: ({ QuoteID, Attributes }) => ({
        url: `/quote/${QuoteID}/attributes`,
        method: "POST",
        body: { Attributes },
      }),
    }),
    getQuoteAttributes: build.query<
      API_RESPONSE<{ [key: string]: string }>,
      { QuoteID: string; Fields: string[] }
    >({
      providesTags: ["Attributes"],
      query: ({ QuoteID, Fields }) => ({
        url: `/quote/${QuoteID}/attributes`,
        params: {
          fields: Fields.join(","),
        },
      }),
    }),
  }),
});

export const {
  useLoadAffiliateDataMutation,
  useLoadQuoteQuery,
  useCreateQuoteMutation,
  useGetLocationInfoQuery,
  useGetDoctorsQuery,
  useGetDoctorsInNetworkQuery,
  useGetInitialMedicinesQuery,
  useSearchMedicinesQuery,
  useGetMedicineDetailsQuery,
  useSearchPharmaciesQuery,
  useGetPharmacyByIDQuery,
  useGetPlansInAreaQuery,
  useGetPlanByIDQuery,
  useGetDoctorsNetworksQuery,
  useGetEnrollmentFormDataQuery,
  usePostEnrollmentDataMutation,
  useSaveMedicineMutation,
  useDeleteMedicineMutation,
  useSaveDoctorMutation,
  useDeleteDoctorMutation,
  useSavePharmacyMutation,
  useDeletePharmacyMutation,
  useSavePlanMutation,
  useDeletePlanMutation,
  useGenerateApplicationIDQuery,
  useGetApplicationStatusQuery,
  useGetDrxEnrollmentQuery,
  useEmailQuoteMutation,
  useGetMedSuppQuestionsQuery,
  useSaveMedSuppAnswersMutation,
  useAddressAutocompleteQuery,
  useGetAzureMapsKeyQuery,
  useGetMedSuppQuoteQuery,
  useGetMedSuppPlanDetailsQuery,
  useGetMedSuppQuoteKeyQuery,
  useGetMedSuppPlanNamesQuery,
  useSaveQuoteAttributesMutation,
  useGetQuoteAttributesQuery,
  useGetHealthConditionsQuery,
  useGetHealthConditionMedicationsQuery,
} = API;
