import { PlanType } from "../types/Plan.types";

export default function getCarrierDisclosure(
  carrier: string,
  planType: PlanType,
  state: string
): React.ReactElement {
  const anthemTest =
    /(anthem|blue rx|amerigroup|healthly|empire|healthy|simply)/i;
  if (anthemTest.test(carrier)) {
    return (
      <>
        <li>
          <i>Anthem Blue Cross</i> - Anthem Blue Cross and Anthem Blue Cross
          Partnership Plan are Medicare Advantage Organizations with Medicare
          contracts. Anthem Blue Cross Life and Health Insurance Company is a
          Medicare Advantage Organization and a Prescription Drug Plan with a
          Medicare contract. For Dual-Eligible Special Needs Plans: Anthem Blue
          Cross and Anthem Blue Cross Partnership Plan are HMO D-SNPs and Anthem
          Blue Cross Life and Health Insurance Company is an LPPO D-SNP with a
          Medicare contract and a contract with the California Medicaid program.
          Enrollment in Anthem Blue Cross, Anthem Blue Cross Partnership Plan,
          and Anthem Blue Cross Life and Health Insurance Company depends on
          contract renewal. Anthem Blue Cross is the trade name of Blue Cross of
          California. Anthem Blue Cross, Anthem Blue Cross Life and Health
          Insurance Company, and Blue Cross of California Partnership Plan, Inc.
          are independent licensees of the Blue Cross Association. Anthem is a
          registered trademark of Anthem Insurance Companies, Inc.
        </li>
        <li>
          <i>Anthem Blue Cross and Blue Shield</i> - Anthem Blue Cross and Blue
          Shield is a Medicare Advantage Organization and a Prescription Drug
          Plan with a Medicare contract. For Dual-Eligible Special Needs Plans:
          Anthem Blue Cross and Blue Shield is an HMO D-SNP with a Medicare
          contract and a contract with the state Medicaid program. Enrollment in
          Anthem Blue Cross and Blue Shield depends on contract renewal. Anthem
          Blue Cross and Blue Shield is the trade name of: In Colorado: Rocky
          Mountain Hospital and Medical Service, Inc. HMO products underwritten
          by HMO Colorado, Inc. In Connecticut: Anthem Health Plans, Inc. In
          Georgia: Blue Cross Blue Shield Healthcare Plan of Georgia, Inc. In
          Indiana: Anthem Insurance Companies, Inc. In Kentucky: Anthem Health
          Plans of Kentucky, Inc. In Maine: Anthem Health Plans of Maine, Inc.
          In Missouri (excluding 30 counties in the Kansas City area):
          RightCHOICE® Managed Care, Inc. (RIT), Healthy Alliance® Life
          Insurance Company (HALIC), and HMO Missouri, Inc. RIT and certain
          affiliates administer non-HMO benefits underwritten by HALIC and HMO
          benefits underwritten by HMO Missouri, Inc. RIT and certain affiliates
          only provide administrative services for self-funded plans and do not
          underwrite benefits. In Nevada: Rocky Mountain Hospital and Medical
          Service, Inc. HMO products underwritten by HMO Colorado, Inc., dba HMO
          Nevada. In New Hampshire: Anthem Health Plans of New Hampshire, Inc.
          HMO plans are administered by Anthem Health Plans of New Hampshire,
          Inc. and underwritten by Matthew Thornton Health Plan, Inc. In Ohio:
          Community Insurance Company. In Virginia: Anthem Health Plans of
          Virginia, Inc. trades as Anthem Blue Cross and Blue Shield in
          Virginia, and its service area is all of Virginia except for the City
          of Fairfax, the Town of Vienna, and the area east of State Route 123.
          In Wisconsin: Blue Cross Blue Shield of Wisconsin (BCBSWI), Compcare
          Health Services Insurance Corporation (Compcare) and Wisconsin
          Collaborative Insurance Company (WCIC). BCBSWI underwrites or
          administers PPO and indemnity policies and underwrites the
          out-of-network benefits in POS policies offered by Compcare or WCIC;
          Compcare underwrites or administers HMO or POS policies; WCIC
          underwrites or administers Well Priority HMO or POS policies.
          Independent licensees of the Blue Cross Blue Shield Association.
          Anthem is a registered trademark of Anthem Insurance Companies, Inc.
        </li>
        <li>
          <i>HealthKeepers</i> - Kroger is a separate and independent company
          offering health and nutritional services on behalf of the Anthem Blue
          Cross and Blue Shield health plans. HealthKeepers, Inc. is a Medicare
          Advantage Organization with a Medicare contract. For Dual-Eligible
          Special Needs Plans: HealthKeepers, Inc. is an HMO D-SNP with a
          Medicare contract and a contract with the Virginia Medicaid program.
          Enrollment in HealthKeepers, Inc. depends on contract renewal.
          HealthKeepers, Inc., an independent licensee of the Blue Cross Blue
          Shield Association, serves all of Virginia except for the City of
          Fairfax, the Town of Vienna, and the area east of State Route 123.
          Anthem is a registered trademark of Anthem Insurance Companies, Inc.
        </li>
        <li>
          <i>Amerigroup</i> - Amerigroup and Amerivantage are Medicare Advantage
          Organizations with Medicare contracts. For Dual-Eligible Special Needs
          Plans: Amerigroup is an HMO D-SNP with a Medicare contract and a
          contract with the state Medicaid program. Enrollment in Amerigroup and
          Amerivantage depends on contract renewal. In New Jersey: Amerivantage
          Dual Coordination (HMO D-SNP) is a D-SNP with a Medicare contract and
          a contract with the New Jersey Medicaid program. Enrollment in
          Amerivantage Dual Coordination (HMO D-SNP) depends on contract
          renewal. Amerigroup Insurance Company is a PDP with a Medicare
          contract. Enrollment in Amerigroup Insurance Company depends on
          contract renewal.
        </li>
        <li>
          <i>Anthem | Maine Health</i> - Anthem | MaineHealth, LLC. is an HMO
          plan with a Medicare contract. AMH Health Plans of Maine, Inc. is an
          LPPO plan with a Medicare contract. Anthem | MaineHealth, LLC. is an
          HMO D-SNP with a Medicare contract and a contract with the Maine
          Medicaid program. Enrollment in Anthem | MaineHealth, LLC. and AMH
          Health Plans of Maine, Inc. depends on contract renewal. Plans offered
          by AMH Health, LLC and AMH Health Plans of Maine, Inc., joint ventures
          between MaineHealth and Anthem Partnership Holding Company, LLC. AMH
          Health, LLC and AMH Health Plans of Maine, Inc. are independent
          licensees of the Blue Cross Blue Shield Association.
        </li>
        <li>
          <i>Blue Rx</i> - Blue Rx is a Prescription Drug Plan with a Medicare
          contract. Enrollment in Blue Rx depends on contract renewal.
          Administered by Empire BlueCross BlueShield and Empire BlueCross in
          cooperation with Excellus BlueCross BlueShield, BlueCross BlueShield
          of Western New York, and Blue Shield of Northeastern New York.
          Independent licensees of Blue Cross Blue Shield Association.
        </li>
        <li>
          <i>Empire BlueCross</i> - Empire BlueCross is a Medicare Advantage
          Organization with a Medicare contract. For Dual-Eligible Special Needs
          Plans: Empire BlueCross is an HMO D-SNP with a Medicare contract and a
          coordination of benefits agreement with the New York State Department
          of Health. Enrollment in Empire BlueCross depends on contract renewal.
          Services provided by Empire HealthChoice HMO, Inc., and/or Empire
          HealthChoice Assurance, Inc., licensees of the Blue Cross Blue Shield
          Association, an association of independent Blue Cross and Blue Shield
          plans.
        </li>
        <li>
          <i>Empire BlueCross BlueShield</i> - Empire BlueCross BlueShield is a
          Medicare Advantage Organization with a Medicare contract. For
          Dual-Eligible Special Needs Plans: Empire BlueCross BlueShield is an
          HMO D-SNP with a Medicare contract and either a contract or a
          coordination of benefits agreement with the New York State Department
          of Health. Enrollment in Empire BlueCross BlueShield depends on
          contract renewal. Services provided by Empire HealthChoice HMO, Inc.,
          and/or Empire HealthChoice Assurance, Inc., and/or HEALTHPLUS HP,
          LLC., licensees of the Blue Cross Blue Shield Association, an
          association of independent Blue Cross and Blue Shield plans.
        </li>
        <li>
          <i>Healthy Blue</i> - Healthy Blue is an HMO D-SNP with a Medicare
          contract and a contract with the Louisiana Medicaid program.
          Enrollment in Healthy Blue depends on contract renewal. Healthy Blue
          is the trade name of Community Care Health Plan of Louisiana, Inc., an
          independent licensee of the Blue Cross Blue Shield Association.
        </li>
        <li>
          <i>Simply Healthcare Plans</i> - Simply Healthcare is a Medicare
          Advantage Organizations with a Medicare contract. Simply Healthcare
          Plans, Inc. is a Medicare-contracted coordinated care plan that has a
          Medicaid contract with the State of Florida Agency for Health Care
          Administration to provide benefits or arrange for benefits to be
          provided to enrollees. Enrollment in Simply Healthcare and Simply
          Healthcare Plans, Inc. depends on contract renewal. Simply Healthcare
          is a Medicare Advantage PPO product underwritten by Unicare Life &
          Health Insurance Company, a licensed Florida Health insurer.
        </li>
      </>
    );
  }

  const aetnaTest = /(aetna)/i;
  if (aetnaTest.test(carrier)) {
    return (
      <>
        <li>
          <a
            href="https://www.aetnamedicare.com/en/footers/disclaimers.html"
            target="_blank"
            rel="noreferrer noopener"
          >
            Aetna Disclaimers
          </a>
        </li>
        {planType !== PlanType.PDP && state === "MN" && (
          <li>
            <a
              href="https://www.allinahealthaetnamedicare.com/en/footers/disclaimers.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              Allina Health | Aetna Medicare's Disclaimers
            </a>
          </li>
        )}
      </>
    );
  }

  const humanaTest = /(humana|arcadian)/i;
  if (humanaTest.test(carrier)) {
    return (
      <>
        <li>
          <i>Humana</i> - Humana is a Medicare Advantage (HMO, HMO SNP, PPO, PPO
          SNP and PFFS) organization and a stand-alone PDP prescription drug
          plan with a Medicare contract. Humana is also a Coordinated Care plan
          with a Medicare contract and a contract with the state Medicaid
          program. Enrollment in any Humana plan depends on contract renewal.
        </li>
        <li>
          <a href="/HumanaDisclaimer.pdf" target="_blank">
            Important Notices
          </a>
        </li>
        {planType === PlanType.PDP && (
          <li>
            <i>Humana</i> - The Humana Premier RX Plan (PDP) and the Humana
            Value RX Plan (PDP) Prescription Drug Plan Prescription Drug Plan
            (PDP) pharmacy network includes limited lower-cost, preferred
            pharmacies in urban areas of AR, CT, DE, IA, IN, KY, MA, MI, MN, MO,
            ND, NJ, NY, OH, RI, SD, TN,WI, WV; suburban areas of CT, DE, HI, IN,
            MA, MI, MN, MT, ND, NJ, NY, OH, PA, PR, RI, WI, WV; and rural areas
            of IA, MN, MT, ND, NE, SD, VT, WY. There are an extremely limited
            number of preferred cost share pharmacies in urban areas in the
            following states: DE, MI, MN, MS, and ND; suburban areas of MT and
            ND; and rural areas of ND. The lower costs advertised in our plan
            materials for these pharmacies may not be available at the pharmacy
            you use. For up-to-date information about our network pharmacies,
            including whether there are any lower-cost preferred pharmacies in
            your area, please call Customer Care at 1-800-281-6918 (TTY: 711) or
            consult the online pharmacy directory at Humana.com
          </li>
        )}
      </>
    );
  }

  const hcscTest =
    /(hcsc|hisc|HEALTH CARE SERVICE CORPORATION|blue cross blue shield|ghs)/i;
  if (hcscTest.test(carrier)) {
    return (
      <>
        <li>
          <i>Blue Cross and Blue Shield of {state}</i> - Blue Cross
          <sup>®</sup>, Blue Shield<sup>®</sup> and the Cross and Shield Symbols
          are registered service marks of the Blue Cross and Blue Shield
          Association, an association of independent Blue Cross and Blue Shield
          Plans.
        </li>
        <li>
          <i>Blue Cross and Blue Shield of {state}</i> - HMO and HMO-POS plans
          provided by Blue Cross and Blue Shield of {state}, a Division of
          Health Care Service Corporation, a Mutual Legal Reserve Company
          (HCSC). HMO plan provided by {state} Blue Cross Blue Shield Insurance
          Company ({state}BCBSIC). HCSC and {state}BCBSIC are Independent
          Licensees of the Blue Cross and Blue Shield Association. HCSC and
          {state}BCBSIC are Medicare Advantage organizations with a Medicare
          contract. Enrollment in HCSC's and {state}BCBSIC's plans depends on
          contract renewal.
        </li>
        <li>
          <i>Blue Cross and Blue Shield of {state}</i> - PPO plans provided by
          Blue Cross and Blue Shield of {state}, a Division of Health Care
          Service Corporation, a Mutual Legal Reserve Company (HCSC), an
          Independent Licensee of the Blue Cross and Blue Shield Association.
          HCSC is a Medicare Advantage organization with a Medicare contract.
          Enrollment in HCSC's plans depends on contract renewal.
        </li>
        <li>
          <i>Blue Cross and Blue Shield of {state}</i> -{" "}
          <a
            href={`https://www.bcbs${state}.com/legal-and-privacy/non-discrimination-notice`}
            target="_blank"
            rel="noreferrer"
          >
            Click Here for Important Anti-Discrimination Notice.
          </a>
        </li>
        {planType === PlanType.PDP && (
          <>
            <li>
              Blue Cross and Blue Shield of {state} - Prescription drug plans
              provided by Blue Cross and Blue Shield of {state}, which refers to
              HCSC Insurance Services Company (HISC), an Independent Licensee of
              the Blue Cross and Blue Shield Association. A Medicare-approved
              Part D sponsor. Enrollment in HISC's plans depends on contract
              renewal.
            </li>
          </>
        )}
        {planType === PlanType.MEDSUPP && (
          <>
            <li>
              {state} should indicate the relevant state the client is a
              resident of.
            </li>
            <li>
              Blue Cross®, Blue Shield® and the Cross and Shield Symbols are
              registered service marks of the Blue Cross and Blue Shield
              Association, an association of independent Blue Cross and Blue
              Shield Plans.
            </li>
            <li>
              Not connected with or endorsed by the U.S. Government or Federal
              Medicare Program.
            </li>
            <li>
              Medicare Supplement insurance plans are offered by Blue Cross and
              Blue Shield of {state}, a Division of Health Care Service
              Corporation, a Mutual Legal Reserve Company, an Independent
              Licensee of the Blue Cross and Blue Shield Association.
            </li>
            <li>
              Click Here for Important Anti-Discrimination Notice. (link to
              Non-Discrimination form)
            </li>
            <li>
              As long as you are a/an {state} resident, age 65 or older, have
              Medicare Part A and are within the six months following your
              enrollment for Medicare Part B, your acceptance is guaranteed.
            </li>
            <li>
              If you are a/an {state} resident, under age 65, have Medicare Part
              A and are within the six months following your enrollment for
              Medicare Part B, your acceptance is guaranteed for Plan A.
            </li>
            <li>
              If you are a/an {state} resident turning age 65, were previously
              enrolled in Medicare Parts A and B, and apply within six months of
              turning age 65, your acceptance is guaranteed.
            </li>
          </>
        )}
      </>
    );
  }

  const wellcareTest = /(wellcare)/i;
  if (wellcareTest.test(carrier)) {
    return (
      <>
        <li>
          <i>WellCare</i> - Wellcare is the Medicare brand for Centene
          Corporation, an HMO, PPO, PFFS, PDP plan with a Medicare contract and
          is an approved Part D Sponsor. Our D-SNP plans have a contract with
          the state Medicaid program. Enrollment in our plans depends on
          contract renewal.
        </li>
        <li>
          <i>WellCare</i> - 'Ohana Health Plan, a plan offered by WellCare
          Health Insurance of Arizona, Inc.
        </li>
        <li>
          <i>WellCare</i> - Texas Residents: Wellcare (HMO and HMO SNP) includes
          products that are underwritten by WellCare of Texas, Inc., WellCare
          National Health Insurance Company, and SelectCare of Texas, Inc.
        </li>
        <li>
          <i>WellCare</i> - Washington residents: "Wellcare" is issued by
          WellCare Health Insurance Company of Washington, Inc.
        </li>
        <li>
          <i>WellCare</i> - Washington residents: Health Net Life Insurance
          Company is contracted with Medicare for PPO plans. "Wellcare by Health
          Net" is issued by Health Net Life Insurance Company.
        </li>
        <li>
          <i>WellCare</i> - Washington residents: "Wellcare" is issued by
          Wellcare of Washington, Inc.
        </li>
        <li>
          <i>WellCare</i> - Every year, Medicare evaluates plans based on a
          5-star rating system.
        </li>
        <li>
          <i>WellCare</i> - Arizona D-SNP plans: Contract services are funded in
          part under contract with the State of Arizona.
        </li>
        <li>
          <i>WellCare</i> - Louisiana D-SNP prospective enrollees: For detailed
          information about Louisiana Medicaid benefits, please visit the
          Medicaid website at{" "}
          <a
            href="https://ldh.la.gov/medicaid."
            target="_blank"
            rel="noreferrer"
          >
            ldh.la.gov/medicaid.
          </a>
        </li>
        <li>
          <i>WellCare</i> - Notice: TennCare is not responsible for payment for
          these benefits, except for appropriate cost sharing amounts. TennCare
          is not responsible for guaranteeing the availability or quality of
          these benefits. Any benefits above and beyond traditional Medicare
          benefits are applicable to Wellcare Medicare Advantage only and do not
          indicate increased Medicaid benefits.
        </li>
        <li>
          <i>WellCare</i> - Indiana D-SNP prospective enrollees: For detailed
          information about Indiana Medicaid benefits, please visit the Medicaid
          website at{" "}
          <a
            href="https://www.in.gov/medicaid/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.in.gov/medicaid/
          </a>
        </li>
        <li>
          <i>WellCare</i> - Wellcare's pharmacy network includes limited
          lower-cost preferred pharmacies in rural areas of MO and NE. The lower
          costs advertised in our plan materials for these pharmacies may not be
          available at the pharmacy you use. For up-to-date information about
          our network pharmacies, including whether there are any lower-cost
          preferred pharmacies in your area, please call 1-833-444-9088 (TTY
          711) for Wellcare No Premium (HMO) and Wellcare Giveback (HMO) in MO
          or consult the online pharmacy directory at{" "}
          <a
            href="https://wellcare.com/medicare"
            target="_blank"
            rel="noreferrer"
          >
            wellcare.com/medicare
          </a>
          ; and 1-833-542-0693 (TTY 711) for Wellcare No Premium (HMO), Wellcare
          Giveback (HMO), and Wellcare No Premium Open (PPO) in NE or consult
          the online pharmacy directory at{" "}
          <a href="https://wellcare.com/NE" target="_blank" rel="noreferrer">
            wellcare.com/NE
          </a>
          .
        </li>
        <li>
          <i>WellCare</i> - We do not offer every plan available in your area.
          Any information we provide is limited to those plans we do offer in
          your area. Please contact Medicare.gov or 1-800-MEDICARE TTY users can
          call 1-877-486-2048, 24 hours a day, 7 days a week to get information
          on all of your options.
        </li>
        <li>
          <i>WellCare</i> - Wellcare is the Medicare brand for Centene
          Corporation, an HMO, PPO, PFFS, PDP plan with a Medicare contract and
          is an approved Part D Sponsor. Our D-SNP plans have a contract with
          the state Medicaid program. Enrollment in our plans depends on
          contract renewal.
        </li>
        <li>
          <i>WellCare</i> - "Wellcare" is issued by WellCare Prescription
          Insurance, Inc.
        </li>
        <li>
          <i>WellCare</i> - We do not offer every plan available in your area.
          Any information we provide is limited to those plans we do offer in
          your area. Please contact Medicare.gov or 1-800-MEDICARE TTY users can
          call 1-877-486-2048, 24 hours a day, 7 days a week to get information
          on all of your options.
        </li>
        <li>
          <i>WellCare</i> - 1. 0 copay on Tier 1 generic drugs when enrolled in
          plans where Walgreens is a Preferred Pharmacy.
        </li>
        <li>
          <i>WellCare</i> - 2. Other Pharmacies/Physicians/Providers are
          available in our network. Please contact your plan for details.
        </li>
        <li>
          <i>WellCare</i> - 3. Advice is free with no obligation to enroll and
          provided by independent licensed agents representing one or more
          plans. Licensed agents are not employees or agents of Walgreens or any
          government agency.
        </li>
      </>
    );
  }

  const bcbsMiTest =
    /(Blue Cross Blue Shield of Michigan|BLUE CARE NETWORK OF MICHIGAN)/i;
  if (bcbsMiTest.test(carrier)) {
    return (
      <>
        <li>
          <i>Blue Cross Blue Shield of Michigan</i> - Medicare Plus BlueSM, BCN
          AdvantageSM and Prescription BlueSM are PPO, HMO, HMO-POS and PDP
          plans with a Medicare contract. Enrollment in Medicare Plus Blue, BCN
          Advantage and Prescription Blue depends on contract renewal.
        </li>
        <li>
          <i>Blue Cross Blue Shield of Michigan</i> - For BCNA (in MI): [BCN
          Advantage HMO is not available in the following counties: Alger,
          Baraga, Cass, Chippewa, Delta, Dickinson, Gogebic, Houghton, Iron,
          Keweenaw, Marquette, Menominee, Ontonagon.]
        </li>
        <li>
          <i>Blue Cross Blue Shield of Michigan</i> - Call{" "}
          <a href="tel:18885633307">1-888-563-3307</a> (TTY users call 711), 8
          a.m. to 9 p.m. Eastern time, Monday through Friday, April 1 to
          September 30; 8 a.m. to 9 p.m. Monday through Friday, with weekend
          hours Oct. 1 through March 31 Enroll online:
          <a
            href="https://www.bcbsm.com/medicare"
            target="_blank"
            rel="noreferrer"
          >
            www.bcbsm.com/medicare
          </a>
          .
        </li>
        <li>
          <i>Blue Cross Blue Shield of Michigan</i> -
          Out-of-network/non-contracted providers are under no obligation to
          treat Medicare Plus Blue PPO and BCN Advantage HMO-POS and HMO
          members, except in emergency situations. For a decision about whether
          we will cover an out-of network service, we encourage you or your
          provider to ask us for a pre-service organization determination before
          you receive the service. Please call our customer service number or
          see your Evidence of Coverage for more information, including the
          cost-sharing that applies to out-of-network services.
        </li>
        <li>
          <i>Blue Cross Blue Shield of Michigan</i> - Every year, Medicare
          evaluates plans based on a 5-star rating system.
        </li>
        <li>
          <i>Blue Cross Blue Shield of Michigan</i> - We do not offer every plan
          available in your area. Any information we provide is limited to those
          plans we do offer in your area. Please contact{" "}
          <a href="https://Medicare.gov" target="_blank" rel="noreferrer">
            Medicare.gov
          </a>{" "}
          or 1-800-MEDICARE to get information on all of your options.
        </li>
      </>
    );
  }

  const cignaTest = /cigna/i;
  if (cignaTest.test(carrier)) {
    return (
      <>
        <li>
          <i>Cigna</i> - All Cigna products and services are provided
          exclusively by or through operating subsidiaries of Cigna Corporation.
          The Cigna name, logos, and other Cigna marks are owned by Cigna
          Intellectual Property, Inc. Cigna contracts with Medicare to offer
          Medicare Advantage HMO and PPO plans and Part D Prescription Drug
          Plans (PDP) in select states, and with select State Medicaid programs.
          Enrollment in Cigna depends on contract renewal. Some content may be
          provided under license. © 2023 Cigna
        </li>
        <li>
          <i>Cigna</i> - This information is not a complete description of
          benefits, which vary by individual plan. Call 1-800-668-3813 (TTY -
          711) for more information.
        </li>
        <li>
          <i>Cigna</i> - Every year, Medicare evaluates plans based on a 5-star
          rating system.
        </li>
        <li>
          <i>Cigna</i> - ATTENTION: If you speak a language other than English,
          language assistance services, free of charge, are available to you.
          Call 1-800-668-3813 (TTY 711) 8am - 8pm local time. Translations of
          this alternate language tagline{" "}
          <a
            href="https://www.cigna.com/static/www-cigna-com/docs/medicare/resources/multi-language-insert-ma.pdf"
            target="_blank"
            rel="noreferrer"
          >
            can be found here
          </a>
          .
        </li>
        <li>
          <i>Cigna</i> - For PPO and POS plans, out-of-network/non-contracted
          providers are under no obligation to treat Cigna members, except in
          emergency situations. Please call our Customer Service number or see
          your Evidence of Coverage for more information, including the
          cost-sharing that applies to out-of-network services. You must live in
          the plan's service area. Call Customer Service at 1-800-668-3813 (TTY
          711), 8 a.m. to 8 p.m. local time, 7 days a week October 1 to March
          31, Monday to Friday April 1 to September 30. Our automated phone
          system may answer your call during weekends, holidays and after hours.
        </li>
        <li>
          <i>Cigna</i> - Individuals may enroll in a plan only during specific
          times of the year and must have Medicare Parts A and B.
        </li>
        <li>
          <i>Cigna</i> - Prior authorization and/or referrals are required for
          certain services.
        </li>
        <li>
          <i>Cigna</i> - We do not offer every plan available in your area. Any
          information we provide is limited to those plans we do offer in your
          area. Please contact Medicare.gov or 1-800-MEDICARE to get information
          on all of your options.
        </li>
      </>
    );
  }

  const ascensionTest = /ascension/i;
  if (ascensionTest.test(carrier)) {
    return (
      <>
        <li>
          <i>Ascension</i> Complete - Ascension Complete is contracted with
          Medicare for HMO and PPO plans. Our D-SNP plans have a contract with
          the state Medicaid program. Enrollment in Ascension Complete depends
          on contract renewal.
        </li>
        <li>
          <i>Ascension</i> Complete - Every year, Medicare evaluates plans based
          on a 5-star rating system.
        </li>
        <li>
          <i>Ascension</i> Complete - Out-of-network/non-contracted providers
          are under no obligation to treat Plan members, except in emergency
          situations. Please call our customer service number or see your
          Evidence of Coverage for more information, including the cost-sharing
          that applies to out-of-network services.
        </li>
        <li>
          <i>Ascension</i> Complete - Other Pharmacies/Physicians/Providers are
          available in our network.
        </li>
        <li>
          <i>Ascension</i> Complete - Please contact your plan for details.
        </li>
        <li>
          <i>Ascension</i> Complete - We do not offer every plan available in
          your area. Any information we provide is limited to those plans we do
          offer in your area. Please contact Medicare.gov or 1-800-MEDICARE to
          get information on all of your options.
        </li>
      </>
    );
  }

  const zingTest = /zing/i;
  if (zingTest.test(carrier)) {
    return (
      <>
        <li>
          <i>Zing Health</i> - Zing Health is a Medicare Advantage Plan with a
          Medicare contract. Enrollment in Zing Health depends on contract
          renewal.
        </li>
      </>
    );
  }

  return <></>;
}
