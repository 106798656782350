import * as React from "react";
import { format } from "date-fns";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  clearCarrierFilters,
  resetAllPlanFilters,
  setEffectiveDate,
  setMonthlyPremium,
  setPlanSortType,
  setPlanYear,
  toggleCarrier,
  togglePlanFeature,
  togglePlanSubType,
} from "../../../redux/reducers/Plan";

import {
  Checkbox,
  FormGroup,
  Paper,
  Typography,
  styled,
  Grid,
  Slider,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
  Collapse,
  useMediaQuery,
  Theme,
  CircularProgress,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import FilterList from "@mui/icons-material/FilterList";

import EffectiveDates from "../../../utils/medAdvEffectiveDates";

import { PlanSortType, PlanType } from "../../../types/Plan.types";
import { ENABLE_DEV_TOOLS } from "../../../config/global.config";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  paddingTop: 0,
}));

const FilterHeader = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(6),
  cursor: "pointer",
  position: "relative",
  zIndex: 1,
  color: "#fff",

  "&::before": {
    content: "''",
    position: "absolute",
    background: theme.palette.primary.main,
    width: "100%",
    height: "53px",
    top: 0,
    zIndex: -1,
  },

  "&::after": {
    content: "''",
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundImage: `url("data:image/svg+xml;utf8,<svg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 120' preserveAspectRatio='none'><path d='M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z' class='shape-fill' fill='${theme.palette.primary.main.replace(
      "#",
      "%23"
    )}' fill-opacity='1'></path></svg>")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "38px",
    zIndex: -1,
    backgroundColor: "transparent",
  },
}));

const MIN_DISTANCE = 60;

function valuetext(value: number) {
  return `$${value}`;
}

function formatPlanFeatureTitle(value: string) {
  switch (value) {
    case "PartB":
      return "Part B";

    case "ForeignTravel":
      return "Foreign Travel";

    case "PartBExcessCharges":
      return "Part B Excess Charges";

    case "SNF":
      return "Skilled Nursing Facilities";

    default:
      return value;
  }
}

interface RootProps {
  currentPlanType: PlanType;
  startTransition: React.TransitionStartFunction;
}

export default function Filters({
  currentPlanType,
  startTransition,
}: RootProps) {
  const dispatch = useAppDispatch();

  const { PlanSort, PlanFilters, PlanMetadata, PlanYear, EffectiveDate } =
    useAppSelector((state) => state.Plan);
  const { Carriers, PlanSubTypes, PlanFeatures } = PlanFilters;
  const PlanMeta = PlanMetadata[`${PlanYear}-${currentPlanType}`];

  /** Premium temp storage before committing to redux state */
  const [tempPremium, setTempPremium] = React.useState<number[]>([0, 1000]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  const [openFilterBox, setFilterBoxOpen] = React.useState(true);

  const [expanded, setExpanded] = React.useState<{ [id: string]: boolean }>({
    carriers: true,
    premium: true,
    features: true,
    type: true,
  });

  const allCarriers = Object.values(Carriers).every((value) => !value);

  const EFFECTIVE_DATE_OPTIONS = React.useMemo(() => {
    return EffectiveDates(PlanYear);
  }, [PlanYear]);

  /**
   * Filters Should be Default Closed on Mobile
   * and Default Open on Desktop and Tablet
   */
  React.useEffect(() => {
    setFilterBoxOpen(!isMobile);
  }, [isMobile]);

  const handleAccordionClick = (id: string) => {
    setExpanded({
      ...expanded,
      [id]: !expanded[id],
    });
  };

  const handleSortChange = (event: SelectChangeEvent<number>) => {
    startTransition(() => {
      dispatch(setPlanSortType(event.target.value as PlanSortType));
    });
  };

  const handleCarrier = (carrier: string, _checked: boolean) => {
    startTransition(() => {
      dispatch(toggleCarrier(carrier));
    });
  };

  const handleAllCarriers = () => {
    startTransition(() => {
      dispatch(clearCarrierFilters());
    });
  };

  const handlePremium = (
    _event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < MIN_DISTANCE) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 1000 - MIN_DISTANCE);
        setTempPremium([clamped, clamped + MIN_DISTANCE]);
      } else {
        const clamped = Math.max(newValue[1], MIN_DISTANCE);
        setTempPremium([clamped - MIN_DISTANCE, clamped]);
      }
    } else {
      setTempPremium(newValue);
    }
  };

  const handlePremiumCommitted = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    if (!Array.isArray(value)) {
      return;
    }
    startTransition(() => {
      dispatch(setMonthlyPremium(value));
    });
  };

  const handleFeature = (id: string, _checked: boolean) => {
    startTransition(() => {
      dispatch(togglePlanFeature(id));
    });
  };

  const handlePlanType = (id: string, _checked: boolean) => {
    startTransition(() => {
      dispatch(togglePlanSubType(id));
    });
  };

  const handleOpen = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  const handlePlanYearChange = (event: SelectChangeEvent<number>) => {
    const newPlanYear = event.target.value as number;
    dispatch(setPlanYear(newPlanYear));

    const NOW = new Date();
    if (newPlanYear > NOW.getFullYear()) {
      dispatch(
        setEffectiveDate(
          format(new Date(NOW.getFullYear() + 1, 0, 1), "M/d/yyyy")
        )
      );
    } else {
      dispatch(
        setEffectiveDate(
          format(new Date(NOW.getFullYear(), NOW.getMonth() + 1, 1), "M/d/yyyy")
        )
      );
    }

    startTransition(() => {
      dispatch(resetAllPlanFilters(`${newPlanYear}-${currentPlanType}`));
    });
  };

  const handleEffectiveDateChange = (event: SelectChangeEvent) => {
    startTransition(() => {
      dispatch(setEffectiveDate(event.target.value));
    });
  };

  return (
    <Paper sx={{ width: { xs: 340, sm: 380 } }}>
      <Grid container direction="column">
        <FilterHeader
          item
          container
          justifyContent="center"
          alignItems="center"
          onClick={handleOpen}
        >
          <FilterList fontSize="large" />
          <Typography variant="h5" fontWeight={600} ml={1}>
            Filter Plans
          </Typography>
        </FilterHeader>
        {!PlanMeta ? (
          <Grid item container justifyContent="center" p={1}>
            <CircularProgress size="4rem" />
          </Grid>
        ) : (
          <Collapse in={openFilterBox} timeout="auto" unmountOnExit>
            <Grid item>
              <FormControl sx={{ m: 3, width: 250 }}>
                <InputLabel id="sort-by-label">Sort By</InputLabel>
                <Select<number>
                  labelId="sort-by-label"
                  id="sort-by-select"
                  value={PlanSort}
                  label="Sort By"
                  onChange={handleSortChange}
                >
                  <MenuItem value={PlanSortType.BEST_MATCH}>
                    Best Match
                  </MenuItem>
                  <MenuItem value={PlanSortType.CARRIER_NAME}>Carrier</MenuItem>
                  <MenuItem value={PlanSortType.LOWEST_PREMIUM}>
                    Lowest Premium
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl sx={{ m: 3, width: 250 }}>
                <InputLabel id="plan-year-label">Plan Year</InputLabel>
                <Select<number>
                  labelId="plan-year-label"
                  id="plan-year-select"
                  value={PlanYear}
                  label="Plan Year"
                  onChange={handlePlanYearChange}
                >
                  <MenuItem value={2024}>2024</MenuItem>
                  <MenuItem value={2025}>2025</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl sx={{ m: 3, width: 250 }}>
                <InputLabel id="effective-date-label">
                  Effective Date
                </InputLabel>
                <Select
                  labelId="effective-date-label"
                  id="effective-date-select"
                  value={EffectiveDate}
                  label="EffectiveDate"
                  onChange={handleEffectiveDateChange}
                >
                  {EFFECTIVE_DATE_OPTIONS.map((option) => {
                    return (
                      <MenuItem key={option.label} value={option.value}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <Accordion
                id="carriers"
                disableGutters
                elevation={0}
                expanded={expanded["carriers"]}
                onChange={() => handleAccordionClick("carriers")}
              >
                <AccordionSummary
                  aria-controls="carriers-content"
                  id="carriers-header"
                >
                  <Typography fontWeight={600}>Carrier</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl>
                    <FormGroup id="carrier-buttons-group">
                      <FormControlLabel
                        value="all"
                        control={
                          <Checkbox
                            color="secondary"
                            checked={allCarriers}
                            onChange={(_event, checked) =>
                              checked && handleAllCarriers()
                            }
                          />
                        }
                        label="All Carriers"
                        sx={{ mb: 1 }}
                      />
                      {Object.keys(Carriers).map((carrier) => {
                        const value = Carriers[carrier];
                        return (
                          <FormControlLabel
                            key={carrier}
                            value={value}
                            control={
                              <Checkbox
                                color="secondary"
                                checked={value}
                                onChange={() => handleCarrier(carrier, value)}
                                sx={{ mt: -1 }}
                              />
                            }
                            label={
                              <Typography textTransform="capitalize">
                                {carrier}
                              </Typography>
                            }
                            sx={{ alignItems: "flex-start", mb: 1 }}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item>
              <Accordion
                id="premium"
                disableGutters
                elevation={0}
                expanded={expanded["premium"]}
                onChange={() => handleAccordionClick("premium")}
              >
                <AccordionSummary
                  aria-controls="premium-content"
                  id="premium-header"
                >
                  <Typography fontWeight={600}>Monthly Premium</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Slider
                    sx={{ mt: 3 }}
                    getAriaLabel={() => "Range for Monthly Premium"}
                    value={tempPremium}
                    onChange={handlePremium}
                    onChangeCommitted={handlePremiumCommitted}
                    valueLabelDisplay="on"
                    getAriaValueText={valuetext}
                    disableSwap
                    step={10}
                    max={1000}
                    valueLabelFormat={(value) => <div>${value}</div>}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
            {PlanMeta.PlanFeatures.length > 0 && (
              <Grid item>
                <Accordion
                  id="features"
                  disableGutters
                  elevation={0}
                  expanded={expanded["features"]}
                  onChange={() => handleAccordionClick("features")}
                >
                  <AccordionSummary
                    aria-controls="features-content"
                    id="features-header"
                  >
                    <Typography fontWeight={600}>Plan Features</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl>
                      <FormGroup defaultValue="" id="features-buttons-group">
                        {Object.keys(PlanFeatures).map((planFeature) => {
                          const value = PlanFeatures[planFeature];
                          return (
                            <FormControlLabel
                              key={planFeature}
                              value={value}
                              control={
                                <Checkbox
                                  color="secondary"
                                  checked={value}
                                  onChange={() =>
                                    handleFeature(planFeature, value)
                                  }
                                />
                              }
                              label={formatPlanFeatureTitle(planFeature)}
                            />
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
            {PlanMeta.PlanSubTypes.length > 0 && (
              <Grid item>
                <Accordion
                  id="type"
                  disableGutters
                  elevation={0}
                  expanded={expanded["type"]}
                  onChange={() => handleAccordionClick("type")}
                >
                  <AccordionSummary
                    aria-controls="type-content"
                    id="type-header"
                  >
                    <Typography fontWeight={600}>Plan Type</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl>
                      <FormGroup defaultValue="" id="plan-sub-types">
                        {Object.keys(PlanSubTypes).map((planSubType) => {
                          const value = PlanSubTypes[planSubType];
                          return (
                            <FormControlLabel
                              key={planSubType}
                              value={value}
                              control={
                                <Checkbox
                                  color="secondary"
                                  checked={value}
                                  onChange={() =>
                                    handlePlanType(planSubType, value)
                                  }
                                />
                              }
                              label={planSubType}
                            />
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
          </Collapse>
        )}
      </Grid>
    </Paper>
  );
}
