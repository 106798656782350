import * as React from "react";
import { InView } from "react-intersection-observer";

import {
  ArrowForward,
  Star,
  StarTwoTone,
  Lightbulb,
  Edit,
  CheckCircle,
  Cancel,
  Medication,
  Article,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  alpha,
} from "@mui/material";

import StarRating from "../../../shared/StarRating";
import {
  SectionTitle,
  SubBenefits,
  RegularBenefit,
  PlanDocuments,
} from "../DetailComponents";

import { formatUSD } from "../../../../utils/formatUSD";
import numberWithCommas from "../../../../utils/numberWithCommas";

import { Plan } from "../../../../types/Plan.types";

const DRX_LOGO_URL = process.env.REACT_APP_DRX_LOGO_URL;

interface RootProps {
  plan: Plan;
  isFavoritePlan: boolean;
  handleFavoritePlan: () => void;
  handleEnroll: () => void;
  handleScrollChange: (sectionId: string, endSection: boolean) => void;
  handleDrugCosts: () => void;
  handleNavigate: (location: "doctors" | "medicines") => void;
}

export default function PpdDetails({
  plan,
  isFavoritePlan,
  handleFavoritePlan,
  handleEnroll,
  handleScrollChange,
  handleDrugCosts,
  handleNavigate,
}: RootProps) {
  const [type, setType] = React.useState("Retail-30");
  const [PharmacyType, DaySupply] = type.split("-");

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  return (
    <Box
      width="100%"
      borderRadius={2}
      border="2px solid #efefef"
      padding={{ xs: 1, md: 3 }}
      sx={{ backgroundColor: "#fff" }}
    >
      <Grid container rowGap={4}>
        <Grid item xs={12} md={6} container direction="column" gap={1}>
          <Grid item>
            <Typography
              textAlign="center"
              sx={(theme) => ({
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                p: 0.5,
                width: 125,
                height: 32,
              })}
              fontWeight={600}
            >
              {plan.PlanYear}
            </Typography>
          </Grid>
          <Grid item>
            <img
              src={`${DRX_LOGO_URL}/${plan.LogoURL}`}
              alt=""
              loading="lazy"
              style={{
                objectFit: "contain",
                objectPosition: "left",
                maxWidth: "100%",
                maxHeight: "50px",
                width: "auto",
                height: "auto",
              }}
            />
          </Grid>
          <Grid item>
            <Typography fontWeight={700} variant="h5">
              {plan.PlanName}
            </Typography>
          </Grid>
          <Grid item>
            {plan.PlanRating ? (
              <StarRating rating={plan.PlanRating} />
            ) : (
              <Typography variant="body2">No Star Rating</Typography>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          alignItems="flex-end"
          justifyContent="flex-start"
          rowGap={1}
        >
          <Grid item container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: 10 }}
              onClick={() => handleEnroll()}
              endIcon={<ArrowForward />}
            >
              Enroll Now
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="inherit"
              startIcon={
                isFavoritePlan ? (
                  <Star color="secondary" />
                ) : (
                  <StarTwoTone color="secondary" />
                )
              }
              sx={{
                backgroundColor: "#cfcfcf",
                borderRadius: 15,
                "&:hover": {
                  backgroundColor: "#cfcfcf",
                  filter: "brightness(0.9)",
                },
              }}
              onClick={() => handleFavoritePlan()}
            >
              {isFavoritePlan && "Added "} Favorite
            </Button>
          </Grid>
        </Grid>

        <SectionTitle
          id="plan-highlights"
          title="Plan Highlights"
          subtitle="High level details to know about your plan"
          icon={<Lightbulb sx={{ fontSize: "3.5rem" }} color="secondary" />}
        />

        <InView
          threshold={0.9}
          onChange={(inView) => {
            if (inView) handleScrollChange("plan-highlights", false);
          }}
        >
          {({ ref }) => (
            <Grid
              ref={ref}
              item
              xs={12}
              md={10}
              container
              direction="column"
              pl={{ xs: 0, md: 10 }}
              rowGap={2}
            >
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <Typography fontWeight={600} variant="h6">
                    Monthly Plan Premium
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    borderBottom: "1.5px solid #efefef",
                    flexGrow: 1,
                    mx: 1,
                  }}
                />
                <Grid item>
                  <Typography fontWeight={600} variant="h6">
                    ${(plan.MedicalPremium + plan.DrugPremium)?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>

              <SubBenefits
                title="Drug Deductible"
                subsections={[
                  {
                    title: "Annual",
                    value:
                      plan.PlanDetail?.InNetwork["DrugDeductibleExclusions"] ??
                      `$${numberWithCommas(plan.DrugDeductible)}`,
                  },
                ]}
              />
            </Grid>
          )}
        </InView>

        <SectionTitle
          id="prescription-benefits"
          title="Prescription Benefits"
          subtitle="What you may pay for your drug coverage"
          icon={<Medication sx={{ fontSize: "3.5rem" }} color="secondary" />}
        />

        <InView
          threshold={0.9}
          onChange={(inView) => {
            if (inView) handleScrollChange("prescription-benefits", false);
          }}
        >
          {({ ref }) => (
            <Grid
              ref={ref}
              item
              xs={12}
              md={10}
              container
              direction="column"
              pl={{ xs: 0, md: 10 }}
              rowGap={2}
            >
              <RegularBenefit
                title="Drug Deductible"
                value={
                  plan.PlanDetail?.InNetwork["DrugDeductibleExclusions"] ??
                  `$${numberWithCommas(plan.DrugDeductible)}`
                }
              />
              <RegularBenefit
                title="Est. Monthly Drug Costs"
                value={
                  plan.PlanDrugCoverage?.length > 0 &&
                  plan.EstimatedMonthlyDrugCosts !== undefined
                    ? `${formatUSD(
                        plan.EstimatedMonthlyDrugCosts - plan.DrugPremium
                      )} per Month`
                    : "N/A"
                }
              />
              <RegularBenefit
                title="Pharmacy Type"
                value={
                  plan.PharmacyCosts?.[0]?.IsPreferred
                    ? "Preferred Cost-Sharing Pharmacy"
                    : "Standard Cost-Sharing Pharmacy"
                }
              />

              <SubBenefits
                title="Your Drug List"
                icon={
                  <Button
                    variant="text"
                    color="secondary"
                    endIcon={<Edit />}
                    onClick={() => {
                      handleNavigate("medicines");
                    }}
                  >
                    Edit
                  </Button>
                }
                subsections={
                  plan.PlanDrugCoverage && plan.PlanDrugCoverage.length > 0
                    ? plan.PlanDrugCoverage.map((drugCoverage) => {
                        const drugCovered = drugCoverage.TierNumber > 0;
                        return {
                          title: drugCoverage.LabelName.toUpperCase(),
                          value: drugCovered ? "Covered" : "Not Covered",
                          icon: drugCovered ? (
                            <CheckCircle color="primary" />
                          ) : (
                            <Cancel color="error" />
                          ),
                        };
                      })
                    : [
                        {
                          title: "No Drugs Selected",
                          value: "",
                        },
                      ]
                }
              />
              <Grid item container justifyContent="flex-end">
                <Button
                  variant="text"
                  color="secondary"
                  endIcon={<ArrowForward />}
                  onClick={() => handleDrugCosts()}
                  disabled={
                    plan.PlanDrugCoverage && plan.PlanDrugCoverage.length === 0
                  }
                >
                  View Drug Costs
                </Button>
              </Grid>

              <Grid item container height={40}>
                <Select
                  value={type}
                  onChange={handleTypeChange}
                  variant="standard"
                >
                  <MenuItem value={"Retail-30"}>30 Day - Retail</MenuItem>
                  <MenuItem value={"Retail-90"}>90 Day - Retail</MenuItem>
                  <MenuItem value={"MailOrder-30"}>
                    30 Day - Mail-Order
                  </MenuItem>
                  <MenuItem value={"MailOrder-90"}>
                    90 Day - Mail-Order
                  </MenuItem>
                </Select>
              </Grid>

              <SubBenefits
                title="Drug Tier Pricing - Preferred Pharmacy"
                subsections={
                  plan.DrugTier
                    ? Object.keys(
                        //@ts-ignore
                        plan.DrugTier.Preferred[PharmacyType][DaySupply]
                      ).map((key) => {
                        const value =
                          //@ts-ignore
                          plan.DrugTier.Preferred[PharmacyType][DaySupply][key];
                        return {
                          title: `Tier ${key}`,
                          value,
                        };
                      })
                    : []
                }
              />

              <SubBenefits
                title="Drug Tier Pricing - Standard Pharmacy"
                subsections={
                  plan.DrugTier
                    ? Object.keys(
                        //@ts-ignore
                        plan.DrugTier.Standard[PharmacyType][DaySupply]
                      ).map((key) => {
                        const value =
                          //@ts-ignore
                          plan.DrugTier.Standard[PharmacyType][DaySupply][key];
                        return {
                          title: `Tier ${key}`,
                          value,
                        };
                      })
                    : []
                }
              />
            </Grid>
          )}
        </InView>

        <SectionTitle
          id="plan-documents"
          title="Plan Documents"
          subtitle="Documents related to the plan"
          icon={<Article sx={{ fontSize: "3.5rem" }} color="secondary" />}
        />

        <InView
          threshold={1}
          onChange={(inView) => {
            if (inView) {
              handleScrollChange("plan-documents", true);
            }
          }}
        >
          {({ ref }) => (
            <Grid
              ref={ref}
              item
              xs={12}
              md={10}
              display="inline-flex"
              flexWrap="wrap"
              justifyContent="center"
              pl={{ xs: 0, md: 10 }}
              gap={2}
            >
              <PlanDocuments
                plan={plan}
                documents={
                  plan.Documents
                    ? plan.Documents.map((document) => {
                        return {
                          title: document.LinkName,
                          link: document.Url,
                        };
                      })
                    : []
                }
              />
            </Grid>
          )}
        </InView>
      </Grid>
    </Box>
  );
}
