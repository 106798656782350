import { format } from "date-fns";

export default function EffectiveDates(planYear: number) {
  const NOW = new Date();

  if (planYear > NOW.getFullYear()) {
    return [
      {
        value: format(new Date(NOW.getFullYear() + 1, 0, 1), "M/d/yyyy"),
        label: format(new Date(NOW.getFullYear() + 1, 0, 1), "LLLL yyyy"),
      },
    ];
  }

  const options: { value: string; label: string }[] = [];

  for (let i = 1; i <= 3; i++) {
    const newDate = new Date(NOW.getFullYear(), NOW.getMonth() + i, 1);
    console.log(newDate);
    if (NOW.getFullYear() < newDate.getFullYear()) {
      break;
    }

    options.push({
      value: format(newDate, "M/d/yyyy"),
      label: format(newDate, "LLLL yyyy"),
    });
  }

  return options;
}
