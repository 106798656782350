import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setCarrier,
  setEnrollPlan,
  setInput,
  setMetadata,
} from "../../../redux/reducers/Enrollment";
import { useGetEnrollmentFormDataQuery } from "../../../redux/services/API";
import { setActiveStep } from "../../../redux/reducers/Quote";

import { styled, alpha } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import LoadingButton from "@mui/lab/LoadingButton";

import DrxEnrollDialog from "./DrxEnrollDialog";
import CallAgentDialog from "./CallAgentDialog";

import getCarrierDisclosure from "../../../utils/getCarrierDisclosure";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";
import useNavigateParams from "../../../utils/useNavigateParams";

import { STEPS } from "../../../types/Global.types";
import { PlanType } from "../../../types/Plan.types";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";
import {
  NATIONAL_NUMBER_OF_CARRIERS,
  NATIONAL_NUMBER_OF_PLANS,
} from "../../../config/global.config";

const DRX_LOGO_URL = process.env.REACT_APP_DRX_LOGO_URL;

const GoBack = styled("span")(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textDecoration: "underline",
  cursor: "pointer",
}));

const MedicareItems = styled(Paper)(({ theme }) => ({
  width: 300,
  borderRadius: 15,
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.secondary.main, 0.27),
}));

const SelectedPlan = styled(Paper)(({ theme }) => ({
  width: "95%",
  maxWidth: 375,
  minHeight: 140,
  borderRadius: 15,
  padding: theme.spacing(2),
}));

export default function ConfirmPlan() {
  const navigate = useNavigateParams();
  const dispatch = useAppDispatch();
  const analytics = useRudderStackAnalytics();

  const { EnrollPlan, Carrier } = useAppSelector((state) => state.Enrollment);
  const { Location, QuoteID } = useAppSelector((state) => state.Quote);
  const { Phone } = useAppSelector((state) => state.Affiliate);

  const [drxEnrollment, setDrxEnrollment] = React.useState(false);
  const [openCallAgentDialog, setOpenCallAgentDialog] = React.useState(false);

  const { data, isFetching, isError } = useGetEnrollmentFormDataQuery(
    {
      Year: EnrollPlan?.Year ?? 0,
      ContractID: EnrollPlan?.ContractID ?? "",
      PlanID: EnrollPlan?.PlanID ?? "",
      SegmentID: EnrollPlan?.SegmentID ?? "",
      UserType: "CONSUMER",
      SnpType: EnrollPlan?.SnpType,
    },
    {
      skip: !EnrollPlan,
    }
  );

  React.useEffect(() => {
    if (!data || !data.data) {
      return;
    }

    dispatch(setCarrier(data.data.carrier.MarketingName));

    if (data.data.form) {
      dispatch(setMetadata(data.data.form.Metadata));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleCancelEnrollment = () => {
    analytics?.track(ANALYTICS_EVENT.URL_BUTTON_CLICKED, {
      quote_id: QuoteID,
      link_text: "Return to Plan Options",
      link_url: "/input/compare",
    });

    dispatch(setEnrollPlan(null));
    dispatch(setMetadata(null));
    dispatch(setCarrier(null));
    dispatch(setActiveStep(STEPS.COMPARE));

    navigate("/input/compare");
  };

  const handleConfirmClick = () => {
    if (!EnrollPlan || !Location || isFetching) {
      return;
    }

    if (EnrollPlan.PlanType === PlanType.MEDSUPP) {
      setOpenCallAgentDialog(true);
      return;
    }

    if (isError || !data) {
      setOpenCallAgentDialog(true);

      analytics?.track(
        ANALYTICS_EVENT.ENROLLMENT_APPLICATION_GENERATION_ERROR,
        {
          quote_id: QuoteID,
          contract_id: EnrollPlan?.ContractID,
          plan_id: EnrollPlan?.PlanID,
          segment_id: EnrollPlan?.SegmentID,
          plan_year: EnrollPlan?.Year,
          snp_type: EnrollPlan?.SnpType,
          plan_type: EnrollPlan?.PlanType,
          enrollment_type: "ERROR",
          error_detail: "NO ENROLLMENT FORM MAPPING",
        }
      );
      return;
    }

    analytics?.track(ANALYTICS_EVENT.CONFIRM_ENROLLMENT_CLICKED, {
      quote_id: QuoteID,
      contract_id: EnrollPlan.ContractID,
      plan_id: EnrollPlan.PlanID,
      segment_id: EnrollPlan.SegmentID,
      plan_year: EnrollPlan.Year,
      snp_type: EnrollPlan.SnpType,
      plan_type: EnrollPlan.PlanType,
      enrollment_type: data.data.formType,
    });

    if (data.data.formType === "DRX") {
      setDrxEnrollment(true);
      return;
    }

    if (data.data.formType === "CALL_AGENT") {
      setOpenCallAgentDialog(true);
      return;
    }

    dispatch(
      setInput({
        "contact-information": {
          County: Location.CountyName,
          ZipCode: Location.ZipCode,
          FIPS: Location.CountyFIPS,
          State: Location.State,
        },
      })
    );

    navigate("/enroll/applicant-information");
  };

  const handleDrxDialogClose = () => {
    setDrxEnrollment(false);
  };

  const handleCallAgentDialogClose = () => {
    setOpenCallAgentDialog(false);
  };

  const handleDrxError = (errorDetail: string) => {
    setDrxEnrollment(false);
    setOpenCallAgentDialog(true);

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_GENERATION_ERROR, {
      quote_id: QuoteID,
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      enrollment_type: "DRX",
      error_detail: errorDetail,
    });
  };

  if (!EnrollPlan) {
    return <Typography>Error: No Plan Selected</Typography>;
  }

  return (
    <>
      <Grid container direction="column" rowGap={4} my={4} px={2}>
        <Grid item textAlign="center">
          <Typography variant="h4" fontWeight={600}>
            You are Almost Done! The Next Part is Simple
          </Typography>
        </Grid>

        <Grid item>
          <Divider />
        </Grid>

        <Grid item container justifyContent="center" gap={2}>
          <Grid item xs={0} md={2} />
          <Grid
            item
            xs={12}
            md={5}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            rowGap={2}
          >
            <Grid item container direction="column" alignItems="center">
              <Typography variant="h6" fontWeight={600}>
                Selected Plan:
              </Typography>
              <SelectedPlan>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  rowGap={1}
                >
                  <Grid item>
                    <img
                      src={`${DRX_LOGO_URL}/${EnrollPlan.LogoURL}`}
                      alt=""
                      width="auto"
                      height="auto"
                      loading="lazy"
                      style={{ maxWidth: "150px", objectFit: "contain" }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography fontWeight={500}>
                      {EnrollPlan.PlanName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <b>${EnrollPlan.QuotedPremium.toFixed(2)}</b> Monthly Plan
                      Premium
                    </Typography>
                  </Grid>
                </Grid>
              </SelectedPlan>
            </Grid>
            <Grid item>
              <Typography>
                <strong>Not the Correct Plan? </strong>
                <GoBack onClick={() => handleCancelEnrollment()}>
                  Return to Plan Options
                </GoBack>
              </Typography>
            </Grid>

            <Grid item>
              <LoadingButton
                variant="contained"
                color="primary"
                sx={{ borderRadius: 10, height: 40 }}
                onClick={() => handleConfirmClick()}
                endIcon={<ArrowForward />}
                loading={isFetching}
              >
                Start Your Enrollment
              </LoadingButton>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} container justifyContent="center">
            <MedicareItems>
              <Grid container>
                <Grid item textAlign="center">
                  <Typography fontWeight={600}>
                    Items to have ready for enrollment
                  </Typography>
                </Grid>

                <Grid item mb={1}>
                  <ol>
                    <li>Medicare Card</li>
                    <li>Primary Care Doctor Name</li>
                    <li>Other insurance coverage card (if necessary)</li>
                  </ol>
                  <Divider flexItem />
                </Grid>

                <Grid item textAlign="center">
                  <Typography fontWeight={600}>
                    Have questions before you enroll?
                  </Typography>
                  <Typography>
                    {formatPhoneNumber(Phone?.PhoneNumber ?? "")}
                  </Typography>
                  <Typography>{Phone?.TTY && "TTY: 711"}</Typography>
                  <Typography>
                    To speak with a licensed representative
                  </Typography>
                </Grid>
              </Grid>
            </MedicareItems>
          </Grid>
        </Grid>

        <Grid item mt={4} container direction="column">
          <Grid item>
            <Typography variant="h4" fontWeight={600}>
              Health Plan Disclaimers
            </Typography>
          </Grid>
          <Grid item>
            <ul>
              <li>
                Allowance amounts cannot be combined with other benefit
                allowances. Limitations and restrictions may apply.
              </li>
              {getCarrierDisclosure(
                Carrier ?? EnrollPlan.Carrier,
                EnrollPlan.PlanType,
                Location?.State ?? ""
              )}
            </ul>
          </Grid>
          <Grid item>
            <Typography fontWeight={700}>
              General Required Disclaimers
            </Typography>
          </Grid>
          <Grid item>
            <ul>
              {EnrollPlan.SnpType === 3 && (
                <li>
                  Benefit(s) mentioned may be part of a special supplemental
                  program for chronically ill members with one of the following
                  conditions: Diabetes mellitus, Cardiovascular disorders,
                  Chronic and disabling mental health conditions, Chronic lung
                  disorders, Chronic heart failure. This is not a complete list
                  of qualifying conditions. Having a qualifying condition alone
                  does not mean you will receive the benefit(s). Other
                  requirements may apply.
                </li>
              )}
              <li>
                The savings number is calculated from all of the saved sessions
                where another consumer entered medication information and their
                current plan. For each saved session, we calculate the cost of
                each plan based on the medication entered and geographical
                location. The savings number is derived by comparing the cost of
                the plan to the cheapest plan in that geographical location and
                taking the average.
              </li>
              <li>
                The retail drug cost is an estimated amount based on the
                out-of-pocket expenses you may expect to pay in a calendar year
                for medications that are not covered by an insurance plan's
                formulary on estimated retail drug price (retail drug cost is
                based on national averages for a medication and assumes
                adherence).
              </li>
              <li>
                If you need help, please call 1-800-299-3116 (TTY User: 711) Mon
                - Fri, 8am - 9pm Sat, 10am - 7pm ET for Customer Service
                Representatives and licensed insurance agents who can assist
                with finding information on available Medicare Advantage,
                Medicare Supplement Insurance and Prescription Drug Plans.
              </li>
              <li>
                We may not offer every plan available in your area. Currently we
                represent {NATIONAL_NUMBER_OF_CARRIERS} Medicare Advantage
                organizations which offer {NATIONAL_NUMBER_OF_PLANS} different
                plan options in your area. Please contact Medicare.gov,
                1-800-MEDICARE, or your local State Health Insurance Program
                (SHIP) to get information on all of your options. You must have
                both Part A and B to enroll in a Medicare Advantage plan.
                Members may enroll in the plan only during specific times of the
                year. Contact the plan for more information.
              </li>
              <li>
                For a complete list of available plans please contact
                1-800-MEDICARE (TTY users should call 1-877-486-2048), 24 hours
                a day/7 days a week or consult{" "}
                <a href="http://www.medicare.gov" target="blank">
                  www.medicare.gov
                </a>
                .
              </li>
              <li>
                You must have Medicare Part A or Part B (or both) to join a
                Medicare Prescription Drug plan. Members may enroll in the plan
                only during specific times of the year. Contact the plan for
                more information.
              </li>
              <li>
                For plans with Part D Coverage: You may be able to get Extra
                Help to pay for your prescription drug premiums and costs. To
                see if you qualify for Extra Help, call: 1-800-MEDICARE
                (1-800-633-4227). TTY users should call 1-877-486-2048, 24 hours
                a day/ 7 days a week or consult{" "}
                <a href="http://www.medicare.gov" target="blank">
                  www.medicare.gov
                </a>
                ; the Social Security Office at 1-800-772-1213 between 7 a.m.
                and 7 p.m., Monday through Friday. TTY users should call,
                1-800-325-0778 or consult{" "}
                <a href="http://www.socialsecurity.gov" target="blank">
                  www.socialsecurity.gov
                </a>
                ; or your Medicaid Office.
              </li>
              <li>
                Every year, Medicare evaluates plans based on a 5-star rating
                system.
              </li>

              <li>
                Medicare beneficiaries can file a complaint with the Centers for
                Medicare & Medicaid Services by calling 1-800-MEDICARE 24 hours
                a day/7 days a week or using the{" "}
                <a
                  href="https://www.medicare.gov/MedicareComplaintForm/home.aspx"
                  target="blank"
                >
                  medicare.gov
                </a>{" "}
                site. Beneficiaries can{" "}
                <a
                  href="http://www.cms.gov/Medicare/CMS-Forms/CMS-Forms/CMS-Forms-Items/CMS012207.html?DLPage=1&amp;DLFilter=1696&amp;DLSort=0&amp;DLSortDir=ascending"
                  target="blank"
                >
                  appoint a representative
                </a>{" "}
                by submitting CMS Form-1696 (or equivalent written notice).
              </li>
              <li>
                Other Pharmacies, Physicians, and Providers are available in the
                network.
              </li>
              <li>
                Pharmacies, Physicians, and Providers may also contract with
                other Plan Sponsors.
              </li>
              <li>
                Out-of-network/non-contracted providers are under no obligation
                to treat Plan members, except in emergency situations. Please
                call the Plan's customer service number or see your Evidence of
                Coverage for more information, including the cost-sharing that
                applies to out-of-network services.
              </li>
              <li>
                <b>
                  Medicare Supplement insurance is available to those age 65 and
                  older enrolled in Medicare Parts A and B and, in some states,
                  to those under age 65 eligible for Medicare due to disability
                  or End-Stage Renal disease. The purpose of this communication
                  is the solicitation of insurance. Contact will be made by a
                  Licensed Insurance Agent or insurance company. Medicare
                  Supplement insurance plans are not connected with or endorsed
                  by the U.S. government or the federal Medicare program.
                </b>
              </li>
              <li>
                Under a contractual relationship between Senior Choice Plans,
                LLC and each insurance company issuing a policy offered by
                Senior Choice Plans, LLC, Senior Choice Plans, LLC earns a
                commission paid by the insurance company for each policy Senior
                Choice Plans, LLC sells. The commission rate varies by policy
                and may increase as Senior Choice Plans, LLC sells more
                policies. In some cases, Senior Choice Plans, LLC may earn bonus
                commission amounts based on criteria such as the number of
                policies sold.
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
      {drxEnrollment && (
        <DrxEnrollDialog
          open={drxEnrollment}
          handleClose={handleDrxDialogClose}
          handleError={handleDrxError}
        />
      )}
      {openCallAgentDialog && (
        <CallAgentDialog
          open={openCallAgentDialog}
          handleClose={handleCallAgentDialogClose}
          planName={EnrollPlan.PlanName}
        />
      )}
    </>
  );
}
